import { Block } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
    headIndex: {
        zIndex: '99',
        '& .w-85':{
            width:'85% !important'
        },
        '& .close_nav': {
            background: 'none !important',
            border: 'none !important',
            float:'right'
        },
        "& $ul": {
            [theme.breakpoints.down("sm")]: {
                paddingLeft: '0',
            },
            "& $li": {
                listStyle: 'none',
                display: 'inline-flex',
                padding: '0 10px',
                [theme.breakpoints.down("992")]: {
                    display: 'Block',
                },
                "& .user_link":{
                    color:"#210B3C !important"
                },
                "& $a": {
                    color: '#FFFFFF',
                    fontWeight: '400',
                    fontSize: '16px',
                    padding:'30px 0 10px',
                    '&:hover': {
                        color: '#7B30AE !important'
                    },
                    [theme.breakpoints.down("992")]: {
                        position: 'relative'
                    },
                    "&::after": {
                        [theme.breakpoints.down("992")]: {
                            content: '""',
                            height: '1px',
                            position: 'absolute',
                            bottom: '0',
                            backgroundColor: '#ffffff',
                            left: '0%',
                            width: '100%',
                        },
                    }
                }
            },

            "& .active": {
                color: '#7B30AE !important',
                fontWeight: '600',
                position: 'relative',
                "&::after": {
                    content: '""',
                    height: '2px',
                    width: '35%',
                    position: 'absolute',
                    left: '35%',
                    bottom: '0',
                    backgroundColor: '#7B30AE',
                    [theme.breakpoints.down("992")]: {
                        left: '0%',
                        width: '100%',
                    },
                }

            }
        },
        "& $button": {
            color: '#ffffff',
            border: '1px solid #FFFFFF',
            borderRadius: '10px',
            fontSize: '16px',
            padding: '9px 32px 10px',
            '&:hover': {
                color: '#ffffff !important',
                border: '1px solid #00000005',
                background: 'transparent linear-gradient(222deg, #FF986C 0%, #ED4EB3 49%, #DB00FF 100%) 0% 0% no-repeat padding-box',
            },

        },
        '& .navbar-toggler': {
            background: '#ffffff',
            [theme.breakpoints.down("992")]: {
                color: '#ffffff',
                border: '1px solid #FFFFFF',
                borderRadius: '10px',
                fontSize: '16px',
                padding: '9px 15px 10px',
            },

        },
        '& .navbar-collapse': {
            [theme.breakpoints.down("992")]: {
                // background: '#000000bf',
                // height: '50vh',                
                // padding: '10px 10px',
                right: '0',
                top: '0',
                position: 'fixed',
                bottom: '0',
                width: '20rem',
                paddingTop: '2.5rem',
                backgroundColor: '#000',
                borderRadius: '0',
                overflowY: 'auto',
                height: ' 100vh',
            },
            [`${theme.breakpoints.between(576, 768)} and (orientation: landscape)`]: {
                height: '90vh',
            },
        },

        '&.header-section': {
            '&.is-sticky': {
                position: 'fixed !important',
                top: '0',
                left: '0',
                width: '100%',
                zIndex: '999',
                boxShadow: '0 2px 24px 0 rgb(0 0 0 / 15%)',
                backgroundColor: '#ffffff !important',
                animation: '500ms ease-in-out 0s normal none 1 running fadeInDown',
                paddingTop: '0px',
                paddingBottom: '0px',
                '& .navbar-collapse': {
                    [theme.breakpoints.down("992")]: {
                        background: '#ffffff !important',
                    }
                },
                "& .img_hide": {
                    display: 'none'
                },
                "& .img_show": {
                    display: 'block !important'
                },
                "& $ul": {
                    "& $li": {
                        "& $a": {
                            color: '#000000 !important',
                            fontWeight: '400',
                            textDecoration: 'none',
                            '&:hover': {
                                color: '#7B30AE !important'
                            },
                        }
                    },
                    "& .active": {
                        color: '#7B30AE !important',
                    }
                },
                "& $button": {
                    color: '#000000 !important',
                    border: '1px solid #000000',
                    borderRadius: '10px',
                    fontSize: '18px',
                    padding: '9px 32px 10px',
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#ffffff !important',
                        border: '1px solid #00000005',
                        background: 'transparent linear-gradient(222deg, #FF986C 0%, #ED4EB3 49%, #DB00FF 100%) 0% 0% no-repeat padding-box',
                    },
                    [theme.breakpoints.down("990")]: {
                        color: '#ffffff',
                        borderRadius: '10px',
                        fontSize: '16px',
                        padding: '9px 15px 10px',
                        background: '#ffffff',
                    },
                },
            }
        },
        '& .navbar-brand': {
            '& $img': {
                [theme.breakpoints.down("sm")]: {
                    width: '210px'
                }
            }
        }

    },
    otherheadIndex: {
        '& $ul': {
            '& $li': {
                '& $a': {
                    color: '#210B3C !important',
                    textDecoration: 'none',
                    [theme.breakpoints.down("992")]: {
                        position: 'relative'
                    },
                    "&::after": {
                        [theme.breakpoints.down("992")]: {
                            content: '""',
                            height: '1px',
                            position: 'absolute',
                            bottom: '0',
                            backgroundColor: '#cdb8e7',
                            left: '0%',
                            width: '100%',
                        },
                    }
                }
            }
        },
        '& $button': {
            border: '1px solid #7B30AE !important',
            color: '#210B3C'
        },
        '& .navbar-collapse': {
            [theme.breakpoints.down("992")]: {
                background: '#ffffff !important',
            }
        },
    }
}))