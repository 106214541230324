import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
    wrapper: {
        // maxWidth: '1920px',
        // margin: '0 auto',
        // display: 'block',
        // position:'relative',
        '& .scrollUp': {
            position: 'fixed',
            right: '5px',
            bottom: '30px',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            backgroundColor: '#ffffff',
            textAlign: 'center',
            cursor: 'pointer',
            zIndex: '500',
            '-webkit-transition': 'all 0.4s ease-in-out 0s',
            transition: 'all 0.4s ease-in-out 0s',
            boxShadow:'0 0 4px 2px #7b30ae',
            '& $svg': {
                display: 'block',
                height: '100%',
                fontSize: '22px',                
                margin: ' 0 auto',
                '&:hover':{
                    color: '#ffffff !important',
                }
            },
            '&:hover': {
                background: 'transparent linear-gradient(237deg, #FF986C 0%, #ED4EB3 49%, #DB00FF 100%) 0% 0% no-repeat padding-box'
            },
            [theme.breakpoints.down('lg')]:{
                width:'30px',
                height:'30px'
            },
            [`${theme.breakpoints.between(1300, 1460)}`]: {
                right: '10px',
                bottom: '230px',
            },
        },    
    },
    qrCode: {
        position: 'fixed',
        bottom: '30px',
        right: '60px',
        background: '#F1F1F1 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 10px #0000004F',
        borderRadius: '10px',
        padding: '10px',
        zIndex: '99',
        [theme.breakpoints.down('lg')]:{
            right:'45px',
            padding:'8px'
        },
        [`${theme.breakpoints.between(1300, 1460)}`]: {
            right: '10px',
            bottom: '10px',
        },
        '& .btnRemove':{
            position: 'absolute',
            background: '#6045e2',
            border: 'none',
            outline: 'none',
            top: '-16px',
            right: '-10px',
            borderRadius: '50%',
            width: '36px',
            height: '36px',
            boxShadow: '0px 0px 3px 1px #818181'
        },
        '& .img_Qr': {
            background: ' #FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
            alignItems: 'center',
            marginBottom:'10px',
            [theme.breakpoints.down('lg')]:{                
                padding:'8px'
            },
            '& $label': {
                fontWeight: '600',
                fontSize: '14px',
                color: '#210B3C',
                textAlign: 'center'
            },
            '& $img': {
                maxWidth: '110px',
                [theme.breakpoints.down('lg')]:{                
                    maxWidth: '90px',
                },
            }
        },
        '& $a': {
            outline: 'none',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            '&:hover':{
                // background:'transparent linear-gradient(237deg, #FF986C 0%, #ED4EB3 49%, #DB00FF 100%) 0% 0% no-repeat padding-box'
                background:'transparent'
            },
            '& $img':{
                maxWidth:'140px',
                [theme.breakpoints.down('lg')]:{                
                    maxWidth: '120px',
                    minHeight: '37px',
                },
            }
        },
        // MuiSvgIcon: {
        //     root: {
        //       color: '#fff !important'
        //     }
        //   }, 
        '& .MuiSvgIcon-root':{
            color:'#fff !important'
        }
    },

}))