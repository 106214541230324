import React, { Suspense, useState, useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import * as Icons from '@material-ui/icons'
import usestyle from '../../../Assets/Styles/Web/layout.js'
import QrCode from '../QrCode'
import CircularLoading from '../../Common/CircularLoading'

const WebLayoutvar = (props) => {
    var classes = usestyle();
    const [isCollapsed, setCollapsed] = useState(true);
    const [showIcon, setShowIcon] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }
    useEffect(() => {
        setTimeout(() => setShowLoader(false), 2000)
        scrollTop()
        window.addEventListener("scroll", showUp);
        return () => {
            window.removeEventListener("scroll", showUp);
        };
    }, []);
    const showUp = () => {
        const scrollTopheight = window.scrollY;
        if (scrollTopheight >= 250) {
            setShowIcon(true)
        }
        else {
            setShowIcon(false)
        }
    }
    const toggleNavbar = () => {
        setCollapsed(!isCollapsed)
        if (isCollapsed === true) {
            document.body.classList.add('canvas-open');
        }
        else {
            document.body.classList.remove('canvas-open');
        }
    }
    return (
        <div className={classes.wrapper}>
            {showLoader ? <CircularLoading /> :
                <>
                    <QrCode />
                    <div id="scrollUp" className={showIcon ? "scrollUp" : "d-none"} title="Scroll To Top" onClick={() => scrollTop()}>
                        <Icons.ArrowUpward
                            size={1.5}
                        />
                    </div>
                    <Header navClick={toggleNavbar} isCollapsed={isCollapsed} />
                    {props.children}
                    <Footer /></>}
        </div>
    )
}

//HOC
const WebLayout = Component => ({ ...props }) => (
    <Suspense fallback={<CircularLoading />}>
        <WebLayoutvar>
            <Suspense fallback={<CircularLoading />}>
                <Component {...props} />
            </Suspense>
        </WebLayoutvar>
    </Suspense>
)
export default WebLayout