export const GiftingType = [
  {
    value: "All",
    name: "All",
  },
  {
    value: "Sticker",
    name: "Sticker",
  },
  {
    value: "Gifted",
    name: "Gifted",
  },
  {
    value: "ChatSticker",
    name: "ChatSticker",
  },
  {
    value: "Referral",
    name: "Referral",
  },
];
