import React from 'react'
import usestyle from '../../../Assets/Styles/User/userheader'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import notification from '../../../Assets/Images1/Users/Icons/notification.png'
import wallet from '../../../Assets/Images1/Users/Icons/wallet.png'
import ranking from '../../../Assets/Images1/Users/Icons/ranking.png'
import gallery from '../../../Assets/Images1/Users/Icons/gallery.png'
import game from '../../../Assets/Images1/Users/Icons/game.png'
import home_selected from '../../../Assets/Images1/Users/Icons/home_selected.svg'
import MenuIcon from '@material-ui/icons/Menu';

export default function UserHeader() {
    var classes = usestyle()
    return (
        <div className={classes.UserHeader}>
            <Container fluid>
                <div className='d-none d-lg-block'>
                    <Row>
                        <Col sm={6} lg={2}>
                            <div className='logo'>
                                <img src="https://1873634302.rsc.cdn77.org/voohoolive/WebImages/Web/logo_white.png" alt="logo" />
                            </div>
                        </Col>
                        <Col sm={8}>
                            <div className='d-none d-lg-flex h-100 align-items-center'>
                                <input type='text' className='searchUser' />
                                <div className='head_options active'>
                                    <img src={home_selected} alt='home' />
                                    <label>Live</label>
                                </div>
                                <div className='head_options'>
                                    <img src={gallery} alt='gallery' />
                                    <label>Photos</label>
                                </div>
                                <div className='head_options'>
                                    <img src={ranking} alt='ranking' />
                                    <label>Starboard</label>
                                </div>
                                <div className='head_options'>
                                    <img src={game} alt='game' />
                                    <label>Games</label>
                                </div>
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className='d-none d-lg-flex align-items-center justify-content-evenly h-100'>
                                <div className='notification'>
                                    <img src={notification} alt='notification' />
                                </div>
                                <Link to='/user/wallet' className='text-decoration-none'>
                                <div className='my_wallet'>
                                    <img src={wallet} alt='wallet' />
                                    <label>My Wallet</label>
                                </div>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='d-flex d-lg-none justify-content-between align-items-center'>
                    <div className='logo'>
                        <img src="https://1873634302.rsc.cdn77.org/voohoolive/WebImages/Web/logo_white.png" alt="logo" />
                    </div>
                    <div className='profileMobIcon'>
                        <label>MM</label>
                        <div className='icoMenu'>
                            <MenuIcon />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
