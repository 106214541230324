import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/styles';
import Themes from './Themes';
import { CssBaseline } from "@material-ui/core";
import App from './Pages/App';
import reportWebVitals from './reportWebVitals';
import { ALayoutProvider } from './Contexts/Admin/AdminLayoutContext'
import { AAuthProvider } from './Contexts/Admin/AdminAuthContext'
import { FLayoutProvider } from './Contexts/Admin/FilterStateContext'
import { U_AuthProvider } from './Contexts/Users/UserAuthContext'
import { persistor, store } from './Store/Admin/Redux/store.js';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

// const reducer = {
//   auth: AauthReducer,
// }
// const store=configureStore({
//   reducer: reducer,
//   devTools: true,
// })
root.render(
  <ALayoutProvider>
    <AAuthProvider>
      <FLayoutProvider>
        <U_AuthProvider>
          <Provider store={store}>
            <ThemeProvider theme={Themes.default}>
              <CssBaseline />
              <PersistGate loading={null} persistor={persistor}>
                <App />
              </PersistGate>
            </ThemeProvider>
          </Provider>
        </U_AuthProvider>
      </FLayoutProvider>
    </AAuthProvider>
  </ALayoutProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
