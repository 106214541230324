import React, { useState } from 'react'
import usestyles from '../../../Assets/Styles/Admin/SideBarStyle.js'
import { List, Drawer } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { SideNavBarList } from "../../../Adapters/Admin/Services"
import SidebarLink from '../SideBarLink'
import classNames from "classnames"

// context
import {
   AuseLayoutState
} from "../../../Contexts/Admin/AdminLayoutContext";


function SideBar() {
   var classes = usestyles()
   const location = useLocation()

   // global
   var { isSidebarOpened } = AuseLayoutState();
   var [isPermanent, setPermanent] = useState(true);
   // var layoutDispatch = AuseLayoutDispatch();
 
   return (
      <Drawer
         variant={isPermanent ? "permanent" : "temporary"}
         className={classNames(classes.drawer, {
            [classes.drawerOpen]: isSidebarOpened,
            [classes.drawerClose]: !isSidebarOpened,
         })}
         classes={{
            paper: classNames({
               [classes.drawerOpen]: isSidebarOpened,
               [classes.drawerClose]: !isSidebarOpened,
            }),
         }}
         open={isSidebarOpened}
      >
         {/* <div className={classes.sidebar} data-trigger="scrollbar">     */}
         <div className="sidebar-body">
            <List className="nav">
               {SideNavBarList().map(link => (
                  <SidebarLink
                     key={link.id}
                     location={location}
                     isSidebarOpened={isSidebarOpened}
                     {...link}
                  />
               ))}
            </List>
         </div>
         {/* </div> */}
      </Drawer>
   )
}
export default SideBar

