import React, { Suspense } from 'react'
import CircularLoading from '../../Common/CircularLoading'

const LoginLayoutvar = (props) => {
    return (
        <div>
            {props.children}
        </div>
    )
}

//HOC
const LoginLayout = Component => ({ ...props }) => (
    <Suspense fallback={<CircularLoading />}>
        <LoginLayoutvar>
            <Suspense fallback={<CircularLoading />}>
                <Component {...props} />
            </Suspense>
        </LoginLayoutvar>
    </Suspense>
)
export default LoginLayout
