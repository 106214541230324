export const Banner_type = [
  {
    name: "Wallet",
    value:"wallet"
  },
  {
    name: "Buy Coin",
    value:"buy_coin"
  },
  {
    name: "Buy Live",
    value:"buy_live"
  },
  {
    name: "Live Info",
    value:"live_info"
  },
  {
    name: "No Link",
    value:"no_link"
  },
  {
    name: "Web Link",
    value:"weblink"
  },
  {
    name: "Post Image",
    value:"post_image"
  },
  {
    name: "Post Video",
    value:"post_video"
  },
  {
    name: "Games",
    value:"games"
  },
  {
    name: "Leaderboard Earner",
    value:"leaderboard_earner"
  },
  {
    name: "Leaderboard Spender",
    value:"leaderboard_spender"
  },
  {
    name: "Weblink Redirect",
    value:"weblink_redirect"
  },
];
