import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import usestyles from "../../../Assets/Styles/Admin/styles.js"
import classNames from 'classnames'
import * as Icons from "@material-ui/icons"
import { AuseAuthDispatch, AuseAuthState } from "../../../Contexts/Admin/AdminAuthContext"
import { signOut } from "../../../Store/Admin/Auth/auth"
import { Popover, Button } from '@material-ui/core'
import Clock from '../CurrentClock'
import LocalStorageService from '../../../Adapters/Admin/Services/LocalStorage.js'
import {
  AuseLayoutState,
  AuseLayoutDispatch,
  toggleSidebar,
} from "../../../Contexts/Admin/AdminLayoutContext"
const cdnURL = process.env.REACT_APP__CDN_URL

export default function Header() {
  var classes = usestyles()
  var today = new Date()
  var day = today.getDay()
  var date = today.getDate()
  var month = today.getMonth() + 1
  var year = today.getFullYear()
  var userDispatch = AuseAuthDispatch();
  var userName = LocalStorageService.getUserName()
  var email = LocalStorageService.getEmail()
  var profileImage = LocalStorageService.getImage()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  var layoutDispatch = AuseLayoutDispatch()
  var { isSidebarOpened } = AuseLayoutState()
  const navigate = useNavigate()
  const onSideBarClick = () => {
    var width = document.body.clientWidth   
    var el = document.getElementsByClassName("mob_overlay")
    if (isSidebarOpened) {
      document.body.className += 'sidebar-folded'
      width < 991 && el[0].classList.remove('active')
    }    
    if (!isSidebarOpened) {   
      document.body.classList.remove('sidebar-folded')
      if (el.length > 0) {
        width < 991 && el[0].classList.add("active")
      }
    }
    toggleSidebar(layoutDispatch)
  }
  const onSignOut = () => {
    navigate("/admin/login")
    signOut(userDispatch)
  }
  return (
    <header className={classNames("white-bg fixed-top d-flex align-content-center flex-wrap", classes.header)}>
      <div className="logo">
        <Link to="/admin">
          <img src={`${cdnURL}/Web/logo_white.png`} alt="logo" className="d-none d-lg-block img-logo" />
          <img src={`${cdnURL}/Web/mob_logo.png`} alt="logo" className="d-block d-lg-none img-logo" />
        </Link>
      </div>
      <div className="main-header">
        <Container fluid>
          <Row className="justify-content-between">
            <Col>
              <div className="main-header-left h-100 d-flex align-items-center">
                <div className={classes.mainHeaderUser}>
                  <div className="d-flex align-items-center">
                    <div className={classes.menuIcon} onClick={() => onSideBarClick()}>
                      {isSidebarOpened ? <Icons.Notes /> : <Icons.ArrowForward />}
                    </div>
                  </div>
                  <Popover
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => {
                      setAnchorEl(null);
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <ul className={classes.ul}>
                      {/* <li><Link to="#">My Profile</Link></li>
                      <li><Link to="#">Settings</Link></li> */}
                      <li><Button color='primary' onClick={() => onSignOut()}>Logout</Button></li>
                    </ul>
                  </Popover>
                </div>
                <div className="main-header-menu d-block d-lg-none">
                  <div className={classes.headerToogleMenu}>
                    <i className="icofont-navigation-menu"></i>
                    <img src="assets/img/menu.png" alt="" />
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className={classNames(classes.mainHeaderRight, classes.mainHeaderUser, "d-flex justify-content-end")}>
                <Link to="#"
                  data-toggle="dropdown"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <div className="user-profile d-flex align-items-center">
                    <div className="user-info d-none d-lg-block">
                      <h4 className="user-name text-end"> {userName}</h4>
                      <p className="user-email text-end">{email}</p>
                    </div>
                    <div className="user-avatar">
                      <img src={profileImage ? profileImage : `${cdnURL}/Admin/dummy_user.jpg`} alt="user" />
                    </div>
                  </div>
                </Link>
                <ul className="nav d-none">
                  <li className="d-none d-lg-flex">
                    <div className={classNames(classes.mainHeaderDateTime, "text-end")} >
                      <h3 className={classNames("time")}>
                        <Clock />
                      </h3>
                      <span className="date"><span id="date">
                        {
                          {
                            '0': 'Sun',
                            '1': 'Mon',
                            '2': 'Tue',
                            '3': 'Wed',
                            '4': 'Thurs',
                            '5': 'Fri',
                            '6': 'Sat'
                          }[day]
                        }, {date + " "}{{
                          '1': 'January',
                          '2': 'February',
                          '3': 'March',
                          '4': 'April',
                          '5': 'May',
                          '6': 'June',
                          '7': 'July',
                          '8': 'August',
                          '9': 'September',
                          '10': 'October',
                          '11': 'November',
                          '12': 'December'
                        }[month]}{" " + year}</span></span>
                    </div>
                  </li>
                  <li className="d-none">
                    <div className={classNames(classes.mainHeaderBtn, "ml-md-1")}>
                      <Link to="#" className="btn">Pending Tasks</Link>
                    </div>
                  </li>
                  <li className="d-none">
                    <div className={classes.mainHeaderMessage}>
                      <Link to="#" className={classes.headerIcon} data-toggle="dropdown">
                        <Icons.ChatBubbleOutline />
                      </Link>
                      <div className="dropdown-menu dropdown-menu-right">
                        <div className="dropdown-header d-flex align-items-center justify-content-between">
                          <h5>3 Unread messages</h5>
                          <Link to="#" className="text-mute d-inline-block">Clear all</Link>
                        </div>
                        <div className="dropdown-body">
                          <Link to="#" className="item-single d-flex media align-items-center">
                            <div className="figure">
                              <img src="assets/img/avatar/m1.png" alt="" />
                              <span className="avatar-status bg-teal"></span>
                            </div>
                            <div className="content media-body">
                              <div className="d-flex align-items-center mb-2">
                                <h6 className="name">Sender Name</h6>
                                <p className="time">2 min ago</p>0
                              </div>
                              <p className="main-text">Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan justo.</p>
                            </div>
                          </Link>
                          <Link to="#" className="item-single d-flex media align-items-center">
                            <div className="figure">
                              <img src="assets/img/avatar/m2.png" alt="" />
                              <span className="avatar-status bg-teal"></span>
                            </div>
                            <div className="content media-body">
                              <div className="d-flex align-items-center mb-2">
                                <h6 className="name">Tonya Lee</h6>
                                <p className="time">2 min ago</p>
                              </div>
                              <p className="main-text">Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan justo.</p>
                            </div>
                          </Link>
                          <Link to="#" className="item-single d-flex media align-items-center">
                            <div className="figure">
                              <img src="assets/img/avatar/m3.png" alt="" />
                              <span className="avatar-status bg-teal"></span>
                            </div>
                            <div className="content media-body">
                              <div className="d-flex align-items-center mb-2">
                                <h6 className="name">Cathy Nichols</h6>
                                <p className="time">2 min ago</p>
                              </div>
                              <p className="main-text">Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan justo.</p>
                            </div>
                          </Link>
                          <Link to="#" className="item-single d-flex media align-items-center">
                            <div className="figure">
                              <img src="assets/img/avatar/m4.png" alt="" />
                              <span className="avatar-status bg-teal"></span>
                            </div>
                            <div className="content media-body">
                              <div className="d-flex align-items-center mb-2">
                                <h6 className="name">Hubert Griffith</h6>
                                <p className="time">2 min ago</p>
                              </div>
                              <p className="main-text">Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan justo.</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="d-none">
                    <div className="main-header-notification">
                      <Link to="#" className={classNames(classes.headerIcon, classes.notificationIcon)} data-toggle="dropdown">
                        <span className="count" data-bg-img="assets/img/count-bg.png">22</span>
                        <Icons.Notifications />
                      </Link>
                      <div className="dropdown-menu style--two dropdown-menu-right">
                        <div className="dropdown-header d-flex align-items-center justify-content-between">
                          <h5>5 New notifications</h5>
                          <Link to="#" className="text-mute d-inline-block">Clear all</Link>
                        </div>
                        <div className="dropdown-body">
                          <Link to="#" className="item-single d-flex align-items-center">
                            <div className="content">
                              <div className="mb-2">
                                <p className="time">2 min ago</p>
                              </div>
                              <p className="main-text">Donec dapibus mauris id odio ornare tempus amet.</p>
                            </div>
                          </Link>
                          <Link to="#" className="item-single d-flex align-items-center">
                            <div className="content">
                              <div className="mb-2">
                                <p className="time">2 min ago</p>
                              </div>
                              <p className="main-text">Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan justo.</p>
                            </div>
                          </Link>
                          <Link to="#" className="item-single d-flex align-items-center">
                            <div className="content">
                              <div className="mb-2">
                                <p className="time">2 min ago</p>
                              </div>
                              <p className="main-text">Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan justo.</p>
                            </div>
                          </Link>
                          <Link to="#" className="item-single d-flex align-items-center">
                            <div className="content">
                              <div className="mb-2">
                                <p className="time">2 min ago</p>
                              </div>
                              <p className="main-text">Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan justo.</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  )
}
