import { Login } from '../../../Adapters/Admin/Api/Login/Login';
import CookieService from '../../../Adapters/Admin/Services/Cookie';
import LocalStorageService from '../../../Adapters/Admin/Services/LocalStorage'
import { decryptedString } from '../../../Adapters/Admin/Services';

// Action Creators
export const signIn = (user, dispatch) => {
    Login(user).then(response => {
        const data = JSON.parse(decryptedString(response.data))        
        if (data.status === 0) {
            dispatch({ type: "ERROR", data: data })
        }
        else {
            CookieService.setToken(data.data.token)
            LocalStorageService.setAuthorization(true)
            dispatch({ type: "LOGIN", data: data })
        }
    })

}

export const signOut = (dispatch,isExpired) => {  
    LocalStorageService.setSession(isExpired)
    CookieService.clearToken()
    LocalStorageService.clearAuthorization()
    LocalStorageService.clearRole() 
    dispatch({ type: "LOGOUT" });    
}
