import React, { useState } from "react";
import {
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { Link } from "react-router-dom";
import Dot from "../../Common/Dot";
import usestyle from '../../../Assets/Styles/Admin/styles.js'
import {
    AuseLayoutState
} from "../../../Contexts/Admin/AdminLayoutContext"

export default function SidebarLink({
    link,
    icon,
    label,
    children,
    location,
    isSidebarOpened,
    nested,
    type,
}) {
    var classes = usestyle()
    var { isSidebarOpened } = AuseLayoutState()
    // local
    var [isOpen, setIsOpen] = useState(link &&
        (location.pathname === link || location.pathname.indexOf(link) !== -1) ? true : false);

    var isLinkActive =
        link &&
        (location.pathname === link || location.pathname.indexOf(link) !== -1);

    if (type === "title")
        return (
            <Typography>
                {label}
            </Typography>
        );

    if (type === "divider") return <Divider />;
    if (link && link.includes('http')) {
        return (
            <ListItem
                button
                disableRipple
            >
                <a href={link}>
                    <ListItemIcon
                        className={!isSidebarOpened && classes.h_41}
                    >
                        {nested ? <Dot color={isLinkActive && "primary"} /> : icon}
                    </ListItemIcon>
                    <ListItemText
                        className={!isSidebarOpened && "d-none"}
                        style={{ marginLeft: "20px" }}
                        primary={label}
                    />
                    {children ?
                        <ListItemIcon
                            className={!isSidebarOpened && classes.h_41}
                        >
                            {isSidebarOpened && (isOpen ? <Icons.Remove className="iconAfter" /> : <Icons.Add className="iconAfter" />)}
                        </ListItemIcon>
                        : ''}
                </a>
            </ListItem>
        )
    }
    if (!children)
        return (
            <ListItem
                button
                component={link && Link}
                to={link}
                disableRipple
            >
                <ListItemIcon
                    className={!isSidebarOpened && classes.h_41}
                >
                    {icon}
                    {/* {nested ? <Dot color={isLinkActive && "primary"} /> : icon} */}
                </ListItemIcon>
                <ListItemText
                    className={!isSidebarOpened && "d-none"}
                    primary={label}
                />
                {children ?
                    <ListItemIcon
                        className={!isSidebarOpened && classes.h_41}
                    >
                        {isSidebarOpened && (isOpen ? <Icons.Remove className="iconAfter" /> : <Icons.Add className="iconAfter" />)}
                    </ListItemIcon>
                    : ''}
            </ListItem>

        );

    return (
        <>
            <ListItem
                button
                component={link && Link}
                onClick={toggleCollapse}
                to={link}
                disableRipple
            >
                <ListItemIcon
                    className={!isSidebarOpened && classes.h_41}
                >
                    {icon ? icon : <Icons.Inbox />}
                </ListItemIcon>
                <ListItemText
                    className={!isSidebarOpened && "d-none"}
                    primary={label}
                />
                {children ?
                    <ListItemIcon
                        className={!isSidebarOpened && classes.h_41}
                    >
                        {isSidebarOpened && (isOpen ? <Icons.Remove className="iconAfter" /> : <Icons.Add className="iconAfter" />)}
                    </ListItemIcon>
                    : ''}
            </ListItem>
            {children && (
                <Collapse
                    in={isOpen}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        {children.map(childrenLink => (
                            <SidebarLink                                
                                key={childrenLink && childrenLink.link}
                                location={location}
                                isSidebarOpened={isSidebarOpened}
                                nested
                                {...childrenLink}
                            />
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );

    // ###########################################################

    function toggleCollapse(e) {
        if (isSidebarOpened) {
            e.preventDefault();
            setIsOpen(!isOpen);
        }
    }
}
