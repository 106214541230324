import { makeStyles } from "@material-ui/styles"
const cdnURL = process.env.REACT_APP__CDN_URL

export default makeStyles(theme => ({
  section: {
    float: 'left',
    inlineSize: '100%'
  },
  footerTop: {
     [theme.breakpoints.down(720)]: {
    // textAlign:'center',
    background:`url(${cdnURL}/Web/mobile_footer.png) 0% 0% no-repeat`,
    padding:' 50px 0 50px',
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
   },
   [`${theme.breakpoints.between(768, 1199)}`]: {
    background: `url(${cdnURL}/Web/footer_bg_small.png) 0% 0% no-repeat`,
    padding: '130px 0 50px',
  },
    '&::after': {
      content: '""',
      backgroundImage: `url(${cdnURL}/Web/footer_design.png)`,
      position: 'absolute',
      width: '160px',
      height: '160px',
      display: 'inline-block',
      top: '25%',
      right: '21%',
      margin: '0 auto',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      [theme.breakpoints.down(720)]: {
       display:'none'
      },
      [`${theme.breakpoints.between(768, 1199)}`]: {
      top:'0'
      },
    },
    background: `url(${cdnURL}/Web/footer_bg.png) 0% 0% no-repeat`,
    padding: '330px 0 50px',
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    position: 'relative',
    "& $h2": {
      fontSize: '18px !important',
      color: '#ffffff',
      fontWeight: '600',
      marginBottom: '30px',
      [theme.breakpoints.down("md")]:{
        marginTop:'20px',
        marginBottom: '15px',
      }
    },
    "& $p": {
      fontSize: '16px',
      color: '#ffffff'
    },
    "& $ul": {
      "& $li": {
        listStyle:'none',
        "& $a": {
          fontSize: '16px',
          color: '#ffffff',
          textDecoration: 'none',
          fontWeight: '400 !important',
          lineHeight: '35px',
          cursor:'pointer',
          [theme.breakpoints.down("md")]:{
            fontSize: '14px',
        },
        }
      }
    },
    [theme.breakpoints.up('1920')]:{
      backgroundPositionY:'25%'
    }
  },
  fLogo: {
    "& $img": {
      width: "290px",
      [theme.breakpoints.down("md")]:{
        width: "200px",
    },
    }
  },
  singleFooterWidget: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '40px',
    '& $ul':{
      '& $li':{
        '& $a':{
          cursor:'pointer'
        }
      }
    }
  },
  footerTopInfo: {
    "& $li": {
      fontSize: '16px',
      color: '#ffffff',
      listStyle: 'none'
    }
  },
  social: {
    marginTop: '40px',
    "& $li":{
      listStyle: 'none'
  },
  "& $a": {
    // background:'#fff !important',
    // marginRight: '15px',
    // padding: '9px 6px 5px',
    // borderRadius: '4px',
    cursor:'pointer',
    '&::hover':{
      background: '#FBAC00 !important',
      // "& $fa": {
        "& $img": {
        color: '#ffffff !important',
        background: '#FBAC00 !important'
    }
    }
},
'& $img':{
  [theme.breakpoints.down("md")]:{
    maxWidth: "40px",
},
}
},
copyrightContent:{
  "& $p": {
    fontSize: '16px',
    fontWeight: '400',
    textAlign: 'center',
    padding: '13px 0',
    color: '#210B3C',
  }
} 
}))


