import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
    UserHeader: {
        fontFamily: 'Inter, sans-serif !important',
        background: '#3F0F6A',
        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.12)',
        textAlign: 'center',
        position: 'fixed',
        width: '100%',
        zIndex: '1024',
        top: '0',
        '& .container-fluid': {
            width: '95% !important',
            [theme.breakpoints.down("1440")]: {
                width: '98% !important',
            },
        },
        '& .searchUser': {
            borderRadius: '74px',
            border: '0.5px solid #FFF',
            background: 'transparent',
            padding: '10px 60px',
            marginRight: '80px',
            outline: 'none',
            [theme.breakpoints.down("1440")]: {
                marginRight: '20px',
                padding: '5px 20px',
            },
        },
        '& .head_options': {
            borderRadius: '25px',
            background: 'rgba(0, 0, 0, 0.20)',
            padding: '10px 20px',
            display: 'flex',
            alignItems: 'center',
            margin: '0 10px',
            [theme.breakpoints.down("1440")]: {
                margin: '0 5px',
                padding: '5px 10px',
            },
            // '&.active': {
            //     background: '#000',
            // },
            '& $img': {
                paddingRight: '10px'
            },
            '& $label': {
                color: ' #FFF',
                fontSize: '16px',
                fontWeight: '500',
                [theme.breakpoints.down("1440")]: {
                    fontSize: '12px',
                },
            }
        },
        '& .logo': {
            padding: '7px 0',
            position: 'relative',
            '& $img': {
                maxWidth: '200px',
                width:'100%'
            },
            '&:after': {
                content: '""',
                display: "block",
                height: "40px",
                overflow: "hidden",
                backgroundColor: "#ffffff",
                opacity: '0.7',
                position: "absolute",
                right: "0",
                width: "1px",
                top: "25%",
                [theme.breakpoints.down("1440")]: {
                    top: "18%",
                },
                [theme.breakpoints.down("769")]: {
                    content: 'none'
                },
            }
        },
        '& .profileMobIcon': {
            borderRadius: '50%',
            border: '1.6px solid #FFF',
            height: '40px',
            width: '40px',
            background: '#FFE2F5',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            '& .icoMenu': {
                position: 'absolute',
                right: '-3px',
                bottom: '-6px',
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                background: '#fff',
                '& .MuiSvgIcon-root': {
                    color: '#000 !important',
                    fontSize: '17px',
                }
            },
            '& $label': {
                color: '#E442AD',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontWeight: '700',
            }
        },
        '& .notification': {
            borderRadius: '25px',
            background: 'rgba(0, 0, 0, 0.20)',
            width: '44px',
            height: '44px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down("1440")]: {
                width: '30px',
                height: '30px',
            }
        },
        '& .my_wallet': {
            display: 'flex',
            padding: '5px 17px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '100px',
            background: 'rgba(255, 255, 255, 0.20)',
            [theme.breakpoints.down("1440")]: {
                padding: '5px 10px',
                gap: '3px',
            },
            '& $label': {
                color: '#FFF',
                fontSize: '14px',
                fontWeight: '600',
                [theme.breakpoints.down("1440")]: {
                    fontSize: '12px',
                },
            }
        }
    },

}))