import React from "react";
import AdminLayout from "../Layout";
import LoginLayout from "../LoginLayout";

const Login = LoginLayout(
  React.lazy(() => import("../../../Pages/Admin/Login"))
);
const ForgotPassword = LoginLayout(
  React.lazy(() => import("../../../Pages/Admin/ForgotPassword"))
);
const ResetPassword = LoginLayout(
  React.lazy(() => import("../../../Pages/Admin/ResetPassword"))
);
const Dashboard = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Dashboard"))
);
const Users = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Users"))
);
const UserUpdate = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UserUpdate"))
);
const UserRoles = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UserRoles"))
);
const UserLive = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UserLiveSessions"))
);
const UserBlockedIp = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/BlockedIp"))
);
// const UserRolesUpdate = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UserRolesUpdate')))
const UserDocVerify = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UserDocVerify"))
);
const UserUpdateDocVerify = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateUserDocVerify"))
);
const UserBulkUpdate = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UserBulkUpdate"))
);
// const AddRoles = AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddUserRole')))
const PushNotification = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/PushNotification"))
);
const Post = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Posts"))
);
const Packages = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Packages"))
);
const AddPackage = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddPackage"))
);
const UpdatePackage = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdatePackage"))
);
const Stickers = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Stickers"))
);
const AddSticker = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddSticker"))
);
const AddBulkSticker = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddBulkSticker"))
);
const UpdateSticker = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateSticker"))
);
const Banners = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Banners"))
);
const AddBanner = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddBanner"))
);
const AddBulkBanner = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddBulkBanner"))
);
const UpdateBanner = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateBanner"))
);
const Diamond = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Diamond"))
);
const AddDiamond = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddDiamond"))
);
const UpdateDiamond = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateDiamond"))
);
const Coupon = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/CouponCode"))
);
const AddCoupon = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddCouponCode"))
);
const UpdateCoupon = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateCouponCode"))
);
const Agency = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Agency"))
);
const AddAgency = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddAgency"))
);
const Withdrawal = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Withdrawal"))
);
const GoLiveStats = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/GoLiveStats"))
);
const Mood = AdminLayout(React.lazy(() => import("../../../Pages/Admin/Mood")));
const UpdateMood = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateMood"))
);
const AddMood = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddMood"))
);
const DiamondStats = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/DiamondStats"))
);
const HistoricalData = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/HistoricalData"))
);
const AddFreeGoodies = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddFreeGoodies"))
);
const FreeGoodies = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/FreeGoodies"))
);
const AddBulkFreeGoodies = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddBulkGoodies"))
);
// const ReadMe=AdminLayout(React.lazy(() => import('../../../Pages/Admin/ReadMe')))
const Languages = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Languages"))
);
const AddLanguage = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddLanguages"))
);
const UpdateLanguage = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateLanguages"))
);
const WalletOffers = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/WalletOffers"))
);
const AddWalletOffers = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddWalletOffers"))
);
const UpdateWalletOffers = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateWalletOffers"))
);
const UserBadges = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Badges"))
);
const AddUserBadges = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddBadges"))
);
const UpdateUserBadges = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateBadges"))
);
// const SendEmail=AdminLayout(React.lazy(()=>import('../../../Pages/Admin/SendEmail')))
const AppVersion = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AppVersion"))
);
const AddAppVersion = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddAppVersion"))
);
const UpdateAppVersion = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateAppVersion"))
);
const FollowerCount = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/FollowerCount"))
);
const CreatorsStat = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/CreatorsStat"))
);
const Events = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Events"))
);
const UpdateEvents = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateEvents"))
);
const AddEvent = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddEvents"))
);
// const TestGmail=AdminLayout(React.lazy(()=>import('../../../Pages/Admin/TestGmail/TestGmail')))
const CategoriesActions = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Categories/CategoriesActions"))
);
const SpenderStickers = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/SpenderStickers"))
);
const AddSpenderSticker = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddSpenderSticker"))
);
const UpdateSpenderSticker = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateSpenderSticker"))
);
const EarnerBadges = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/EarnerBadges"))
);
const AddEarnerBadge = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddEarnerBadge"))
);
const UpdateEarnerBadge = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateEarnerBadge"))
);
const ThreedStickers = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/ThreedStickers"))
);
const AddThreedSticker = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddThreedSticker"))
);
const UpdateThreedSticker = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateThreedSticker"))
);
const PaidEntryEffects = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/PaidEntryEffects"))
);
const AddPaidEntryEffect = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddPaidEntryEffect"))
);
const UpdatePaidEntryEffect = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdatePaidEntryEffect"))
);
const PaidBadges = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/PaidBadges"))
);
const AddPaidBadge = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddPaidBadge"))
);
const UpdatePaidBadge = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdatePaidBadge"))
);
const Frames = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Frames"))
);
const AddFrame = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddFrame"))
);
const UpdateFrame = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateFrame"))
);
const UserGiftingData = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UserGiftingData"))
);
const Bundles = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/Bundles"))
);
const AddBundles = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddBundles"))
);
const UpdateBundles = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateBundles"))
);

const BulletMessages = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/BulletMessages"))
);
const AddBulletMessage = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddBulletMessage"))
);
const UpdateBulletMessage = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateBulletMessage"))
);
const UserwiseAssignedItemsEntryEffects = AdminLayout(
  React.lazy(() =>
    import("../../../Pages/Admin/UserwiseAssignedItemsEntryEffects")
  )
);
const AddUserwiseAssignedItemsEntryEffects = AdminLayout(
  React.lazy(() =>
    import("../../../Pages/Admin/AddUserwiseAssignedItemsEntryEffects")
  )
);
const UserwiseAssignedItemsBadges = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UserwiseAssignedItemsBadges"))
);
const AddUserwiseAssignedItemsBadges = AdminLayout(
  React.lazy(() =>
    import("../../../Pages/Admin/AddUserwiseAssignedItemsBadges")
  )
);
const UserwiseAssignedItemsFrames = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UserwiseAssignedItemsFrames"))
);
const AddUserwiseAssignedItemsFrames = AdminLayout(
  React.lazy(() =>
    import("../../../Pages/Admin/AddUserwiseAssignedItemsFrames")
  )
);
const UserwiseAssignedItemsBulletMessages = AdminLayout(
  React.lazy(() =>
    import("../../../Pages/Admin/UserwiseAssignedItemsBulletMessages")
  )
);
const UpdateCategory = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateCategory/UpdateCategory"))
);
const AddUserwiseAssignedItemsBulletMessages = AdminLayout(
  React.lazy(() =>
    import("../../../Pages/Admin/AddUserwiseAssignedItemsBulletMessages")
  )
);
const UsersConsTransactions = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UsersConsTransactions"))
);
const UsersDetailedViewTransactions = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UsersDetailedViewTransactions"))
);
const GiftingData = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/GiftingData"))
);
const UsersDetailedViewGifting = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UsersDetailedViewGifting"))
);
const StickerOffers = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/StickerOffers"))
);
const AddStickerOffers = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddStickerOffers"))
);
const UpdateStickerOffers = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateStickerOffers"))
);
const AssignStickerOffers = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AssignStickerOffers"))
);
const PkBattles = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/PkBattles"))
);
const HostWisePkBattles = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/HostWisePkBattles"))
);
const AddEntryEffectBundle = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddEntryEffectBundle"))
);
const AddBadgeBundle = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddBadgeBundle"))
);
const AddFrameBundle = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddFrameBundle"))
);
const AddBulletMessageBundle = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddBulletMessageBundle"))
);
const MobilesBlock = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/MobilesBlock"))
);
const AddMobilesBlock = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddMobilesBlock"))
);
const GlobalSettings = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/GlobalSettings"))
);
const AddGlobalSettings = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/AddGlobalSettings"))
);
const UpdateGlobalSettings = AdminLayout(
  React.lazy(() => import("../../../Pages/Admin/UpdateGlobalSettings"))
);

const adminRoutes = [
  { path: "/admin/login", element: Login, exact: true, secureRoute: false },
  {
    path: "/admin/forgotpassword",
    element: ForgotPassword,
    exact: true,
    secureRoute: false,
  },
  {
    path: "/admin/usergiftingdata",
    element: UserGiftingData,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/resetpassword",
    element: ResetPassword,
    exact: true,
    secureRoute: false,
  },
  { path: "/admin", element: Dashboard, exact: true, secureRoute: true },
  {
    path: "/admin/dashboard",
    element: Dashboard,
    exact: true,
    secureRoute: true,
  },
  { path: "/admin/users", element: Users, exact: true, secureRoute: true },
  { path: "/admin/users/list", element: Users, exact: true, secureRoute: true },
  {
    path: "/admin/users/list/update/:userId",
    params: "userId",
    element: UserUpdate,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/users/roles",
    element: UserRoles,
    exact: true,
    secureRoute: true,
  },
  // { path: '/admin/users/roles/update/:roleId', params: "roleId", element: UserRolesUpdate, exact: true, secureRoute: true },
  // { path: '/admin/users/roles/add/', element: AddRoles, exact: true, secureRoute: true },
  {
    path: "/admin/users/live",
    element: UserLive,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/users/blockedip",
    element: UserBlockedIp,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/users/bulk_update",
    element: UserBulkUpdate,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/users/categories",
    element: CategoriesActions,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/users/categories/add",
    element: UpdateCategory,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/users/follower_count",
    element: FollowerCount,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/users/document-verify",
    element: UserDocVerify,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/users/document-verify/update/:userId",
    params: "userId",
    element: UserUpdateDocVerify,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/pushnotification",
    element: PushNotification,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/pushnotification/:typeId",
    element: PushNotification,
    exact: true,
    secureRoute: true,
  },
  { path: "/admin/posts", element: Post, exact: true, secureRoute: true },
  {
    path: "/admin/packages",
    element: Packages,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/package/update/:packageId",
    element: UpdatePackage,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/package/add/",
    element: AddPackage,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/stickers",
    element: Stickers,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/sticker/update/:stickerId",
    params: "stickerId",
    element: UpdateSticker,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/sticker/add/",
    element: AddSticker,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/sticker/addbulk/",
    element: AddBulkSticker,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/spenderstickers",
    element: SpenderStickers,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/spendersticker/add/",
    element: AddSpenderSticker,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/spendersticker/update/:stickerId",
    params: "stickerId",
    element: UpdateSpenderSticker,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/earnerbadges",
    element: EarnerBadges,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/earnerbadge/add/",
    element: AddEarnerBadge,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/earnerbadge/update/:badgeid",
    params: "badgeid",
    element: UpdateEarnerBadge,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/3Dstickers",
    element: ThreedStickers,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/3Dsticker/add/",
    element: AddThreedSticker,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/3Dsticker/update/:stickerId",
    params: "stickerId",
    element: UpdateThreedSticker,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/paidentryeffects",
    element: PaidEntryEffects,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/paidentryeffect/add/",
    element: AddPaidEntryEffect,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/paidentryeffect/update/:stickerId",
    params: "stickerId",
    element: UpdatePaidEntryEffect,
    exact: true,
    secureRoute: true,
  },

  { path: "/admin/banners", element: Banners, exact: true, secureRoute: true },
  {
    path: "/admin/banner/update/:bannerId",
    params: "bannerId",
    element: UpdateBanner,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/banner/add/",
    element: AddBanner,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/banner/addbulk/",
    element: AddBulkBanner,
    exact: true,
    secureRoute: true,
  },
  { path: "/admin/diamond", element: Diamond, exact: true, secureRoute: true },
  {
    path: "/admin/diamond/update/:diamondId",
    params: "diamondId",
    element: UpdateDiamond,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/diamond/add/",
    element: AddDiamond,
    exact: true,
    secureRoute: true,
  },
  { path: "/admin/coupon", element: Coupon, exact: true, secureRoute: true },
  {
    path: "/admin/coupon/add/",
    element: AddCoupon,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/coupon/update/:couponId",
    params: "couponId",
    element: UpdateCoupon,
    exact: true,
    secureRoute: true,
  },
  { path: "/admin/agency", element: Agency, exact: true, secureRoute: true },
  { path: "/admin/agency/add", element: AddAgency, exact: true },
  {
    path: "/admin/miscellaneous/withdrawal",
    element: Withdrawal,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/miscellaneous/golivestats",
    element: GoLiveStats,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/miscellaneous/creatorstats",
    element: CreatorsStat,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/miscellaneous/historicaldata",
    element: HistoricalData,
    exact: true,
    secureRoute: true,
  },
  { path: "/admin/mood/add", element: AddMood, exact: true, secureRoute: true },
  { path: "/admin/moods", element: Mood, exact: true, secureRoute: true },
  {
    path: "/admin/mood/update/:moodId",
    params: "moodId",
    element: UpdateMood,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/miscellaneous/diamondstats",
    element: DiamondStats,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/miscellaneous/freegoodies/add",
    element: AddFreeGoodies,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/miscellaneous/freegoodies/add/:typeId",
    element: AddFreeGoodies,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/miscellaneous/freegoodies",
    element: FreeGoodies,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/miscellaneous/freegoodies/addbulk",
    element: AddBulkFreeGoodies,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/languages",
    element: Languages,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/languages/add/",
    element: AddLanguage,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/languages/update/:languageId",
    element: UpdateLanguage,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/wallet-offers",
    element: WalletOffers,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/wallet-offers/update/:walletOfferId",
    element: UpdateWalletOffers,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/wallet-offers/add/",
    element: AddWalletOffers,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/user-badges",
    element: UserBadges,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/user-badges/update/:userBadgeId",
    element: UpdateUserBadges,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/user-badges/add/",
    element: AddUserBadges,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/paidbadges",
    element: PaidBadges,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/paidbadge/add/",
    element: AddPaidBadge,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/paidbadge/update/:badgeid",
    params: "badgeid",
    element: UpdatePaidBadge,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/frames",
    element: Frames,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/frame/add/",
    element: AddFrame,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/frame/update/:frameid",
    params: "frameid",
    element: UpdateFrame,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/bulletmessages",
    element: BulletMessages,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/bulletmessage/add/",
    element: AddBulletMessage,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/bulletmessage/update/:bulletmsgid",
    params: "bulletmsgid",
    element: UpdateBulletMessage,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/app-versions",
    element: AppVersion,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/app-versions/add/",
    element: AddAppVersion,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/app-versions/update/:versionid",
    params: "versionid",
    element: UpdateAppVersion,
    exact: true,
    secureRoute: true,
  },
  { path: "/admin/bundles", element: Bundles, exact: true, secureRoute: true },
  {
    path: "/admin/bundle/add/",
    element: AddBundles,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/bundle/update/:bundleid",
    element: UpdateBundles,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/bundle/add/entryeffect",
    element: AddEntryEffectBundle,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/bundle/add/badge",
    element: AddBadgeBundle,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/bundle/add/frame",
    element: AddFrameBundle,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/bundle/add/bulletmessage",
    element: AddBulletMessageBundle,
    exact: true,
    secureRoute: true,
  },
  { path: "/admin/event", element: Events, exact: true, secureRoute: true },

  {
    path: "/admin/event/update/:eventId",
    element: UpdateEvents,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/event/add/",
    element: AddEvent,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/userwiseassigneditems",
    element: UserwiseAssignedItemsEntryEffects,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/userwiseassigneditemsentryeffects",
    element: UserwiseAssignedItemsEntryEffects,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/userwiseassigneditemsentryeffects/add/",
    element: AddUserwiseAssignedItemsEntryEffects,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/userwiseassigneditemsbadges",
    element: UserwiseAssignedItemsBadges,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/userwiseassigneditemsbadges/add/",
    element: AddUserwiseAssignedItemsBadges,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/userwiseassigneditemsframes",
    element: UserwiseAssignedItemsFrames,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/userwiseassigneditemsframes/add/",
    element: AddUserwiseAssignedItemsFrames,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/userwiseassigneditemsbulletmessages",
    element: UserwiseAssignedItemsBulletMessages,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/userwiseassigneditemsbulletmessages/add/",
    element: AddUserwiseAssignedItemsBulletMessages,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/usertransactions",
    element: UsersConsTransactions,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/usertransactions/consolidateddata",
    element: UsersConsTransactions,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/usertransactions/detailedview/:userid",
    params: "userid",
    element: UsersDetailedViewTransactions,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/usertransactions/giftingdata",
    element: GiftingData,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/usertransactions/giftingdata/detailedview/:fromId/:toId",
    params: ["fromId", "toId"],
    element: UsersDetailedViewGifting,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/stickeroffers",
    element: StickerOffers,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/stickeroffers/add/",
    element: AddStickerOffers,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/stickeroffers/update/:stickerofferid",
    element: UpdateStickerOffers,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/stickeroffers/assign/:stickerofferid",
    element: AssignStickerOffers,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/pkbattles",
    element: PkBattles,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/pkbattles/hostwise",
    element: HostWisePkBattles,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/mobilesblocked",
    element: MobilesBlock,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/mobilesblocked/add",
    element: AddMobilesBlock,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/globalsettings",
    element: GlobalSettings,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/globalsettings/add",
    element: AddGlobalSettings,
    exact: true,
    secureRoute: true,
  },
  {
    path: "/admin/globalsettings/update/:_id",
    params: "_id",
    element: UpdateGlobalSettings,
    exact: true,
    secureRoute: true,
  },
  //  //  { path: '/admin/send-email/', element: SendEmail, exact: true, secureRoute: true },
  //     { path: '/admin/readme',  element: ReadMe, exact: true , secureRoute: true },
  // { path: '/admin/gmailtest',  element: TestGmail, exact: true , secureRoute: true },
];
export { adminRoutes };

// export default function AdminRoutes() {
//     return (
//         <>
//             {adminRoutes.map((item, key) => {
//                 if (typeof item.secureRoute !== 'undefined' && item.secureRoute == true)
//                     return <PrivateRoute exact={item.exact} path={item.path} element={item.element} key={key} />
//                 else
//                     return <Route exact={item.exact} path={item.path} element={<item.element />} key={key} />
//             })}
//         </>
//     )
// }
