import { useEffect } from "react"
import {
    AuseLayoutDispatch,
    toggleMobSidebar,
} from "../../..//Contexts/Admin/AdminLayoutContext"

export default function MainContentClick(ref) {
  var width = document.body.clientWidth
  var layoutDispatch = AuseLayoutDispatch()
  var el = document.getElementsByClassName("mob_overlay")
  useEffect(() => {
    function handleClickOutside(event) {   
      if (ref.current && event.target.classList.contains('mob_overlay')) {       
        if (width < 991) {
            toggleMobSidebar(layoutDispatch)
            width < 991 && el[0].classList.remove("active")
        }
      }    
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
