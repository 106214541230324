import React from "react";
import LocalStorageService from "../../Adapters/Users/Services/user_LocalStorage";

var AuthStateContext = React.createContext();
var AuthDispatchContext = React.createContext();

const initialState = {
  token: '',
  userName: '',
  profileImage:'',
  lastActive: '',
  role: '',
  loginDate: '',
  isAuthenticated: JSON.parse(localStorage.getItem("isAuthenticated")) ?
    JSON.parse(localStorage.getItem("isAuthenticated")) : false,  
  signingIn: false,
  signingOut: false,
  error: false,
  errorMessage: '',
}

function U_authReducer(state=initialState, action) {  
  switch (action.type) {   
    case "LOGIN":
    //   LocalStorageService.setRole(action.data.data.user.role)   
    //   LocalStorageService.setUserName(action.data.data.user.username)   
    //   LocalStorageService.setEmail(action.data.data.user.email)
    //   LocalStorageService.setImage(action.data.data.user.profile.image)
      return {
        ...state,
        isAuthenticated: true,
        // userName: action.data.data.user.username,
        // email:action.data.data.user.email,
        // profileImage:action.data.data.user.profile.image,
        token: action.data.data.token,
        // role: action.data.data.user.role,
        // lastActive: action.data.data.user.updatedAt,
        loginDate: new Date()
      };
    case "LOGOUT":
     // localStorage.clear();
     LocalStorageService.clearUserAuthorization()
      return {
        ...state,
        isAuthenticated: false,
        // userName: null,
        // profileImage:null,
        token: null,
        // lastActive: null,
        // role: null,
        // lastActive:null,
        loginDate:null
      };
      case "ERROR":
        localStorage.clear();
        return {
          ...state,
          isAuthenticated: false,
        //   userName: null,
        //   profileImage:null,
          token: null,
        //   lastActive: null,
        //   role: null,
        //   errorMessage:action.data.errorMessage
        };
    default:
      return state;
  }

}

function U_AuthProvider({ children }) {
  var [state, dispatch] = React.useReducer(U_authReducer, {
    isAuthenticated: !!localStorage.getItem("is_u_Authenticated"),
    // role: !!localStorage.getItem("user_role"),
  });
  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

function U_useAuthState() {
  var context = React.useContext(AuthStateContext);  
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  return context;
}

function U_useAuthDispatch() {
  var context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }
  return context;
}

export { U_AuthProvider, U_useAuthState, U_useAuthDispatch,U_authReducer };

// ###########################################################
