import React from 'react'
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap'
import useStyles from "../../../Assets/Styles/Web/footer"
import { Link } from 'react-router-dom'
const cdnURL = process.env.REACT_APP__CDN_URL

export default function Footer() {
    var classes = useStyles();
    return (
        <footer className={classNames(classes.section)}>
            <div className={classes.footerTop}>
                <Container>
                    <Row>
                        <Col xs={12} sm={6} md={4}>
                            <Link to="http://voohoolive.com/" className={classes.fLogo}>
                                <img src={`${cdnURL}/Web/logo_white.png`} alt="logo" />
                            </Link>
                            <div className={classNames(classes.social, "justify-content-start")}>
                                <ul className="d-flex m-0 p-0">
                                    <li>
                                        <a title="Facebook" href="https://www.facebook.com/VOOHOOliveApp/" target="_blank" rel="noreferrer">
                                            <img src={`${cdnURL}/Web/SocialIcons/Facebook.png`} alt="Facebook" />
                                        </a> </li>
                                    <li><a title="Linkedin" href="https://in.linkedin.com/company/voohoo?original_referer=https%3A%2F%2Fwww.google.com%2F" target="_blank" rel="noreferrer"><img src={`${cdnURL}/Web/SocialIcons/Linkedin.png`} alt="Linkedin" /></a> </li>
                                    {/* <li><a title="Twitter" href="https://twitter.com/VOOHOO_Official" target="_blank" rel="noreferrer"><img src={`${cdnURL}/Web/SocialIcons/Twitter.png`} alt="Twitter" /></a> </li> */}
                                    <li><a title="Twitter" href="https://twitter.com/VOOHOOlive" target="_blank" rel="noreferrer"><img src={`${cdnURL}/Web/SocialIcons/Twitter.png`} alt="Twitter" /></a> </li>
                                    <li><a title="Youtube" href="https://www.youtube.com/channel/UCB0OF3SNEHnbaq9FSYKCUDA" target="_blank" rel="noreferrer"><img src={`${cdnURL}/Web/SocialIcons/Youtube.png`} alt="Youtube" /></a> </li>
                                    <li><a title="Instagram" href="https://www.instagram.com/voohooofficial/" target="_blank" rel="noreferrer"><img src={`${cdnURL}/Web/SocialIcons/Instagram.png`} alt="Instagram" /></a></li>
                                </ul>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4}>
                            <h2 className={classes.h2}>Useful Links</h2>
                            <div className={classes.singleFooterWidget}>
                                <ul className="p-0 mb-0">
                                    <li><Link to="/gdpr" rel="nofollow">GDPR</Link></li>
                                    <li><Link to="/eula" rel="nofollow">EULA</Link></li>
                                    <li><Link to="/terms-of-use">Terms of Service</Link></li>
                                    <li><Link to="/copyright-policy">Copyright Policy</Link></li>
                                    <li><Link to="/anti-phishing">Anti-Phishing</Link></li>
                                </ul>
                                <ul className="p-0">
                                    <li><Link to="/ad-policy" rel="nofollow">Ad Policy</Link></li>
                                    <li><Link to="/cookie-policy" rel="nofollow">Cookie Policy</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/wallet-items-policy">Wallet Items Policy</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4}>
                            <div className="d-none d-md-block">
                                <h2>Contact Us</h2>
                                <p>Want to drop suggestions & ideas or want us to address any query or concern.</p>
                                <div className={classes.footerTopInfo}>
                                    <ul className="p-0">
                                        <li className="fw-semibold">Email Address: <Link to="mailto:mailto:support@voohoolive.com"
                                            className="fw-regular text-decoration-underline">support@voohoolive.com</Link> </li>
                                        <li className="mt-3"> <strong>Address : </strong>Emaar Palm Hills, Sector 77, Gurgaon, Haryana-122004 </li>

                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row className="align-items-center">
                    <Col xs={12}>
                        <div className={classes.copyrightContent}>
                            <p className="mb-0">© Copyrights 2023 VOOHOO. All rights reserved.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
