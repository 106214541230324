import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Container, Nav, Navbar } from 'react-bootstrap'
import useStyles from "../../../Assets/Styles/Web/header"
import * as Icons from '@material-ui/icons'
const cdnURL = process.env.REACT_APP__CDN_URL

export default function OtherHeader(props) {
  var classes = useStyles()
  var [path, setPath] = useState('')
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    setPath(window.location.pathname)
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  return (
    <header className={classNames(classes.headIndex, classes.otherheadIndex, "position-absolute header-section", "w-100")}>
      <Container>
        <Navbar expand="lg" className="w-100">
          <Navbar.Brand href="/">
            <img src={`${cdnURL}/Web/logo_dark.png`} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={props.navClick} className="close_nav">
              <Icons.Close />
            </Navbar.Toggle>
              {/* <Nav className="mx-auto"> */}
              <Nav className="ms-auto">
              <ul>
                <li><Link to="/" className={path == "/" ? "active nav-link" : "nav-link"}>Home</Link></li>
                <li> <Link to="/partner" className={path == "/partner" ? "active nav-link" : "nav-link"}>Partner</Link></li>
                <li><Link to="/about-us" className={path == "/about-us" ? "active nav-link" : "nav-link"}>About Us</Link></li>
                {/* <li> <Link to="#link" className="nav-link">Community</Link></li> */}
                <li><Link to="/contact-us" className={path == "/contact-us" ? "active nav-link" : "nav-link"}>Contact</Link></li>
                <li><a href='https://blog.voohoolive.com/' className="nav-link" target="_blank" rel="noreferrer">Blog</a></li>   
              </ul>
            </Nav>
            {/* <Button variant="outline">Login</Button> */}
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  )
}

