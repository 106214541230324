import React from "react"
import UserLayout from "../UserLayout"
import LoginLayout from "../LoginLayout"

const UserLogin=LoginLayout(React.lazy(()=>import('../../../Pages/Users/Login')))
const MobileNumberLogin=LoginLayout(React.lazy(()=>import('../../../Pages/Users/MobileNumberLogin')))
const OtpVerification=LoginLayout(React.lazy(()=>import('../../../Pages/Users/OtpVerification')))
const ProfileDetails = UserLayout(React.lazy(() => import('../../../Pages/Users/ProfileDetails')))
const Home= UserLayout(React.lazy(() => import('../../../Pages/Users/Home')))
const UserWallet = UserLayout(React.lazy(() => import('../../../Pages/Users/UserWallet')))
const WalletHistory = UserLayout(React.lazy(() => import('../../../Pages/Users/WalletHistory')))
const WithdrawDiamonds = UserLayout(React.lazy(() => import('../../../Pages/Users/WithdrawDiamonds')))
const WithdrawalHistory = UserLayout(React.lazy(() => import('../../../Pages/Users/WithdrawalHistory')))
const WalletBuyCoins= UserLayout(React.lazy(() => import('../../../Pages/Users/WalletBuyCoins')))
const WalletBuyCoinsSelect=UserLayout(React.lazy(() => import('../../../Pages/Users/WalletBuyCoinsSelect')))
const WalletAddBank=UserLayout(React.lazy(() => import('../../../Pages/Users/WalletAddBank')))
const WalletAddNewCard=UserLayout(React.lazy(() => import('../../../Pages/Users/WalletAddNewCard')))
const WalletBuyMinutes=UserLayout(React.lazy(() => import('../../../Pages/Users/WalletBuyMinutes')))
const WalletBuyMinutesFAQ=UserLayout(React.lazy(() => import('../../../Pages/Users/WalletBuyMinutesFAQ')))

const userRoutes = [
    { path: "/user/login", element: UserLogin, exact: true },
    { path: "/user/mobile-login", element: MobileNumberLogin, exact: true },
    { path: "/user/otp-verify", element: OtpVerification, exact: true },
    { path: "/user/profile-details", element: ProfileDetails, exact: true},
    { path: "/user/home", element: Home, exact: true,secureRoute: true},
    { path: "/user/wallet", element: UserWallet, exact: true,secureRoute: true },
    { path: "/user/wallet-history", element: WalletHistory, exact: true,secureRoute: true },
    { path: "/user/withdraw-diamonds", element: WithdrawDiamonds, exact: true,secureRoute: true },
    { path: "/user/withdrawal-history", element: WithdrawalHistory, exact: true,secureRoute: true },
    { path: "/user/wallet-buy-coins", element: WalletBuyCoins, exact: true,secureRoute: true },
    { path: "/user/wallet-buy-coins-select", element: WalletBuyCoinsSelect, exact: true,secureRoute: true },
    { path: "/user/wallet-add-bank", element: WalletAddBank, exact: true,secureRoute: true },
    { path: "/user/wallet-add-newCard", element: WalletAddNewCard, exact: true,secureRoute: true },
    { path: "/user/wallet-buy-golive", element: WalletBuyMinutes, exact: true ,secureRoute: true},
    { path: "/user/wallet-buy-live-faq", element: WalletBuyMinutesFAQ, exact: true ,secureRoute: true},
];
export { userRoutes }
