import axios, * as others from 'axios';
const baseURL = process.env.REACT_APP_API_BASE_URL;

export const Login =async (user) => {
    var response=axios({
        method: "POST",
        url: baseURL + "/auth/login",
        data: {
            email: user.email,
            password: user.password,
            deviceos: "react",
            device_origin: user.role
        }

    }).then(res => {       
        return res
    }).catch(err => { console.log(err) });
    return response   
}
