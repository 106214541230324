import tinycolor from "tinycolor2";

const primary = "#6045e2";
const secondary = "#acacac";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#E3319D";
const error = "#D43232";
const description = "#EDE6D6";

//added 31-12-2021 for new theme
const gradient_start = "#792EB0";
const gradient_center = "#A73C92";
const gradient_end = "#D54A74";
const newBackground = "#141d51";
///
const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    error: {
      main: error,
      light: tinycolor(error)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(error)
        .darken(darkenRate)
        .toHexString(),
    },
    description: {
      main: description,
      light: tinycolor(description)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(description)
        .darken(darkenRate)
        .toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9 ",
      //hint:"#141d51 !important",
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
    gradient_start: {
      main: gradient_start,
      light: tinycolor(gradient_start)
        .lighten(gradient_start)
        .toHexString(),
      dark: tinycolor(gradient_start)
        .darken(darkenRate)
        .toHexString(),
    },
    gradient_center: {
      main: gradient_center,
      light: tinycolor(gradient_center)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(gradient_center)
        .darken(darkenRate)
        .toHexString(),
    },
    gradient_end: {
      main: gradient_end,
      light: tinycolor(gradient_end)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(gradient_end)
        .darken(darkenRate)
        .toHexString(),
    },
    newBackground: {
      main: newBackground,
      light: tinycolor(newBackground)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(newBackground)
        .darken(darkenRate)
        .toHexString(),
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: [
            'Poppins',
            'sans-serif',
          ].join(','),
          backgroundColor: '#f0f0f0'
        },
      },
    }
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        color: '#6045e2 !important'
      }
    },
    MuiList: {
      root: {
        paddingLeft: '15px'
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'inherit !important'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '0px !important'
      }
    },
    MuiFormControl: {
      root: {
        margin: '10px 0'
      }
    },
    MuiPopover: {
      paper: {
        maxWidth: '200px'
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: '0 0 2px 1px #a495eb'
      }
    },
    MuiInputBase: {
      input: {
        '@media (max-width:767px)': {
          fontSize: '12px'
        },
      }
    },  
  }
};

export default defaultTheme