import { makeStyles } from "@material-ui/styles";

const drawerWidth = 260;

export default makeStyles((theme) => ({
  sidebar: {
    zIndex: "2",
    backgroundColor: "#FCFCFC",
    overflowY: "scroll",
    [theme.breakpoints.down("md")]: {
      "-webkit-transform": "translateX(-100%)",
      "-ms-transform": "translateX(-100%)",
      transform: "translateX(-100%)",
    },
  },

  "body.sidebar-folded": {
    "& .sidebar": {
      width: "60px",
      "& .sidebar-body": {
        "& $li": {
          "& $i": {
            color: "#727272",
          },
          "&.nav-category": {
            visibility: "hidden",
            maxHeight: "24px",
            "&:before": {
              position: "absolute",
              content: '""',
              width: "5px",
              height: "2px",
              left: "0",
              top: "50%",
              "-webkit-transform": "translateY(-50%)",
              "-ms-transform": "translateY(-50%)",
              transform: "translateY(-50%)",
              visibility: "visible",
            },
          },
          "& $a": {
            "& $span": {
              visibility: "hidden",
              opacity: "0",
            },
          },
          "&.active,&:hover": {
            "& $i": {
              color: "#8280FD",
            },
          },
        },
      },
    },
    "&.open-sidebar-folded": {
      "& .sidebar": {
        width: "260px",
        "& .sidebar-body": {
          "& .sidebar-toogle-pin": {
            opacity: "0.5",
          },
          "& $li": {
            "&.active,&:hover": {
              "& $i": {
                color: " #8280FD",
              },
            },
            "& $i": {
              color: "#aeaeae",
            },
            "&.nav-category": {
              visibility: "visible",
              "&:before": {
                display: "none",
              },
            },
            "& $a": {
              "& $span": {
                visibility: "visible",
                opacity: "1",
              },
            },
          },
        },
      },
    },
  },
  "body.sidebar-open": {
    "& .sidebar": {
      "-webkit-transform": "translateX(0)",
      "-ms-transform": "translateX(0)",
      transform: " translateX(0)",
    },
    "& .header": {
      "& .header-toogle-pin": {
        opacity: "0.5",
      },
    },
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    position: "fixed",
    left: "0",
    "& .sidebar-header": {
      padding: "20px 21px 0px",
      "& .sidebar-toogle-pin": {
        width: "35px",
        height: "35px",
        cursor: "pointer",
        fontSize: "24px",
        display: " inline-block",
        marginLeft: "-5px",
      },
    },
    "& .sidebar-body": {
      padding: "0px 18px 100px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "11px",
      },
      "& $ul": {
        "&.nav": {
          "-webkit-box-orient": "vertical",
          "-webkit-box-direction": "normal",
          "-ms-flex-direction": "column",
          flexDirection: "column",
        },
        "& $a": {
          padding: "0 0 0 35px",
          position: "relative",
          marginBottom: "5px",
          "&.nav-category": {
            padding: "0",
            fontSize: "17px",
            textTransform: "capitalize",
            margin: "10px 0 15px",
          },
          "& $i,& $svg": {
            position: "absolute",
            left: "0",
            top: "7px",
            fontSize: "22px",
            color: "#aeaeae !important",
            "&:hover": {
              color: "#8280FD !important",
            },
          },
          "& $span": {
            display: "block",
            padding: "5px",
            paddingLeft: "0px",
            height: "30px",
            overflow: "hidden",
            "&.disabled": {
              cursor: "not-allowed",
              opacity: ".5",
            },
          },
          "&.has-sub-item": {
            "&:after": {
              position: "absolute",
              content: '""',
              backgroundImage: "url(../img/svg/plus.svg)",
              width: "12px",
              height: "12px",
              right: "0",
              top: "10px",
              zIndex: "-1",
            },
            "&.sub-menu-opened": {
              "&:after": {
                height: "2px",
                top: "15px",
                backgroundImage: "url(../img/minus.png)",
              },
            },
          },
          "&.active": {
            "& $i": {
              color: "#8280FD",
            },
            "& $a": {
              "&.disabled": {
                "& $i": {
                  color: "#aeaeae",
                },
              },
            },
          },
          "&:hover": {
            "& $i,& $svg": {
              color: "#8280FD",
            },
            "& $a": {
              "&.disabled": {
                "& $i": {
                  color: "#aeaeae",
                },
              },
            },
          },
        },
        "& $ul": {
          paddingLeft: "15px",
        },
        "&.sub-menu": {
          display: "none",
          "& $li": {
            padding: "0",
            "&:before": {
              position: "absolute",
              content: '""',
              width: "5px",
              height: "1px",
              left: "-17px",
              top: "15.5px",
            },
          },
        },
      },
    },
  },
  drawerOpen: {
    width: drawerWidth,
    top: "90px !important",
    zIndex: "1029",
    [theme.breakpoints.down("992")]: {
      top: "80px !important",
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    top: "90px !important",
    width: theme.spacing(7) + 40,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  /* sidebarList: {
    marginTop: theme.spacing(6),
  }, */
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: 18,
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
