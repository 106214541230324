import React, { useState } from 'react'
import classNames from 'classnames'
import * as Icons from '@material-ui/icons'
import usestyle from '../../../Assets/Styles/Web/layout.js'
const cdnURL = process.env.REACT_APP__CDN_URL

export default function QrCode() {
    var classes = usestyle();
    const [showScan, setShowScan] = useState(true)
    return (
        <>
        {showScan==true?
            <div className={classNames(classes.qrCode, "d-none d-lg-block")}>
                <button type='button' className='btnRemove' onClick={()=>setShowScan(!showScan)}>
                    <Icons.Close />
                </button>
                <div className="img_Qr">
                    <label className="my-2">Download App</label>
                    <img src={`${cdnURL}/Web/QrCode_VooHoo_new.jpg`} alt="QR Code" />
                </div>
                <a href="https://play.google.com/store/apps/details?id=com.sg.voohoo&hl=en_IN" target="_blank" rel="noreferrer" className="my-3">
                    <img src={`${cdnURL}/Web/Google_play_black.png`} alt="Google play" />
                </a><br></br>
                <a href="https://apps.apple.com/in/app/voohoo-live/id6446180349" target="_blank" rel="noreferrer" className="mt-1 mb-0 d-block">
                    <img src={`${cdnURL}/Web/app_store.png`} alt="IOS" />
                </a>
            </div>:
            <div className={classNames(classes.qrCode, "d-none d-lg-block")}>
                  <button type='button' className='btnRemove' onClick={()=>setShowScan(!showScan)}>
                    <Icons.AddOutlined />
                </button>
                  <a href="https://play.google.com/store/apps/details?id=com.sg.voohoo&hl=en_IN" target="_blank" rel="noreferrer" className="my-3">
                    <img src={`${cdnURL}/Web/Google_play_black.png`} alt="Google play" />
                </a><br></br>
                <a href="https://apps.apple.com/in/app/voohoo-live/id6446180349" target="_blank" rel="noreferrer" className="mt-1 mb-0 d-block">
                    <img src={`${cdnURL}/Web/app_store.png`} alt="IOS" />
                </a>
            </div>
            }
        </>
    )
}
