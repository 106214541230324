import LocalStorageService from "./LocalStorage";
import * as Icons from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  iconColor: {
    color: "#0000008a !important",
  },
}));
export const SideNavBarList = () => {
  var role = LocalStorageService.getRole();
  var classes = useStyles();

  if (role === "super" || role === "admin") {
    return [
      {
        id: 0,
        label: "Dashboard",
        link: "/admin/dashboard",
        icon: <Icons.DashboardOutlined className={classes.iconColor} />,
      },
      {
        id: 1,
        label: "User",
        link: "/admin/users",
        icon: <Icons.People className={classes.iconColor} />,
        children: [
          {
            label: "List",
            link: "/admin/users/list",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Live Sessions",
            link: "/admin/users/live",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Blocked Ip",
            link: "/admin/users/blockedip",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Roles",
            link: "/admin/users/roles",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Followers",
            link: "/admin/users/follower_count",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Categories",
            link: "/admin/users/categories",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Document Verify",
            link: "/admin/users/document-verify",
            icon: <Icons.VerifiedUser className={classes.iconColor} />,
          },
        ],
      },
      {
        id: 2,
        label: "Posts",
        link: "/admin/posts",
        icon: <Icons.Wallpaper className={classes.iconColor} />,
      },
      {
        id: 3,
        label: "Packages",
        link: "/admin/packages",
        icon: <Icons.Redeem className={classes.iconColor} />,
      },
      {
        id: 4,
        label: "Stickers",
        link: "/admin/stickers",
        icon: <Icons.Star className={classes.iconColor} />,
      },
      {
        id: 5,
        label: "Entry Effects",
        link: "/admin/EntryEffects",
        icon: <Icons.People className={classes.iconColor} />,
        children: [
          {
            label: "Spender Entry Effect",
            link: "/admin/spenderstickers",
            icon: <Icons.Star className={classes.iconColor} />,
          },
          {
            label: "Paid Entry Effect",
            link: "/admin/paidentryeffects",
            icon: <Icons.Star className={classes.iconColor} />,
          },
        ],
      },

      // {
      //   id: 6,
      //   label: "3D Stickers",
      //   link: "/admin/3Dstickers",
      //   icon: <Icons.Pages className={classes.iconColor} />,
      // },

      {
        id: 6,
        label: "Banners",
        link: "/admin/banners",
        icon: <Icons.ViewCarousel className={classes.iconColor} />,
      },
      {
        id: 7,
        label: "Diamond",
        link: "/admin/diamond",
        icon: <Icons.Details className={classes.iconColor} />,
      },
      {
        id: 8,
        label: "Coupon Code",
        link: "/admin/coupon",
        icon: <Icons.Assessment className={classes.iconColor} />,
      },
      {
        id: 9,
        label: "Agency",
        link: "/admin/agency",
        icon: <Icons.Accessibility className={classes.iconColor} />,
      },
      {
        id: 10,
        label: "Moods",
        link: "/admin/moods",
        icon: <Icons.Mood className={classes.iconColor} />,
      },
      {
        id: 11,
        label: "Languages",
        link: "/admin/languages",
        icon: <Icons.Language className={classes.iconColor} />,
      },
      {
        id: 12,
        label: "App Versions",
        link: "/admin/app-versions",
        icon: <Icons.Android className={classes.iconColor} />,
      },
      {
        id: 13,
        label: "Wallet Offers",
        link: "/admin/wallet-offers",
        icon: <Icons.LocalOffer className={classes.iconColor} />,
      },
      {
        id: 14,
        label: "Sticker Offers",
        link: "/admin/stickeroffers",
        icon: <Icons.AccountBalanceWallet className={classes.iconColor} />,
      },
      {
        id: 15,
        label: "User Badges",
        link: "/admin/user-badges",
        icon: <Icons.Face className={classes.iconColor} />,
      },
      {
        id: 16,
        label: "Badges",
        link: "/admin/Badges",
        icon: <Icons.Label className={classes.iconColor} />,
        children: [
          {
            label: "Earner Badges",
            link: "/admin/earnerbadges",
            icon: <Icons.Label className={classes.iconColor} />,
          },

          {
            label: "Paid Badges",
            link: "/admin/paidbadges",
            icon: <Icons.Label className={classes.iconColor} />,
          },
        ],
      },
      ,
      {
        id: 17,
        label: "Frames",
        link: "/admin/frames",
        icon: <Icons.Crop className={classes.iconColor} />,
      },
      {
        id: 18,
        label: "Bullet Messages",
        link: "/admin/bulletmessages",
        icon: <Icons.Message className={classes.iconColor} />,
      },
      {
        id: 19,
        link: "/admin/bundles",
        label: "Bundles",
        icon: <Icons.LocalAtm className={classes.iconColor} />,
      },
      {
        id: 20,
        link: "/admin/event",
        label: "Event",
        icon: <Icons.Event className={classes.iconColor} />,
      },
      {
        id: 21,
        link: "/admin/mobilesblocked",
        label: "Mobiles Blocked",
        icon: <Icons.Block className={classes.iconColor} />,
      },
      {
        id: 22,
        link: "/admin/globalsettings",
        label: "Global Settings",
        icon: <Icons.Settings className={classes.iconColor} />,
      },
      // {
      //   id: 20,
      //   label: "User Gifting Data",
      //   link: "/admin/usergiftingdata",
      //   icon: <Icons.CardGiftcard className={classes.iconColor} />,
      // },
      {
        id: 23,
        label: "PK Battles",
        link: "/admin/pkbattles",
        icon: <Icons.SportsMma className={classes.iconColor} />,
        children: [
          {
            label: "All PK Battles",
            link: "/admin/pkbattles",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Host wise PK Battles",
            link: "/admin/pkbattles/hostwise",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
        ],
      },
      {
        id: 24,
        label: "User Wise Items",
        link: "/admin/userwiseassigneditems",
        icon: <Icons.People className={classes.iconColor} />,
        children: [
          {
            label: "Entry Effects",
            link: "/admin/userwiseassigneditemsentryeffects",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Badges",
            link: "/admin/userwiseassigneditemsbadges",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Frames",
            link: "/admin/userwiseassigneditemsframes",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Bullet Messages",
            link: "/admin/userwiseassigneditemsbulletmessages",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
        ],
      },
      {
        id: 25,
        label: "User Transactions",
        link: "/admin/usertransactions",
        icon: <Icons.AccountBalance className={classes.iconColor} />,
        children: [
          {
            label: "Consolidated Data",
            link: "/admin/usertransactions/consolidateddata",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Gifting Data",
            link: "/admin/usertransactions/giftingdata",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
        ],
      },
      {
        id: 26,
        label: "Miscellaneous",
        link: "/admin/miscellaneous/golivestats",
        icon: <Icons.Apps className={classes.iconColor} />,
        children: [
          {
            label: "Go Live Stats",
            link: "/admin/miscellaneous/golivestats",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Withdrawal",
            link: "/admin/miscellaneous/withdrawal",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Push Notification",
            link: "/admin/pushnotification",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Diamond Stats",
            link: "/admin/miscellaneous/diamondstats",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Creator Stats",
            link: "/admin/miscellaneous/creatorstats",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Historical Data",
            link: "/admin/miscellaneous/historicaldata",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
          {
            label: "Free Goodies",
            link: "/admin/miscellaneous/freegoodies",
            icon: <Icons.ChevronRight className={classes.iconColor} />,
          },
        ],
      },
      { id: 27, type: "divider" },
      // { id: 17, type: "title", label: "Description" },
      // {
      //     id: 18,
      //     label: "Read Me",
      //     link: "/admin/readme",
      //     icon: <Icons.ChromeReaderMode className={classes.iconColor} />,
      // },
      // {
      //     id: 16,
      //     label: "Send Email",
      //     link: "/admin/send-email",
      //     icon: <Icons.MailOutline />,
      // }
    ];
  } else if (role === "agency") {
    return [
      { id: 0, label: "Dashboard", link: "/app/dashboard" },
      {
        id: 1,
        label: "Typography",
        link: "/app/typography",
      },
      { id: 2, label: "Tables", link: "/app/tables" },
      {
        id: 3,
        label: "Notifications",
        link: "/app/notifications",
      },
      {
        id: 4,
        label: "UI Elements",
        link: "/app/ui",

        children: [
          { label: "Icons", link: "/app/ui/icons" },
          { label: "Charts", link: "/app/ui/charts" },
          { label: "Maps", link: "/app/ui/maps" },
        ],
      },
      { id: 5, type: "divider" },
      { id: 6, type: "title", label: "HELP" },
      { id: 7, label: "Library", link: "https://flatlogic.com/templates" },
      { id: 8, label: "Support", link: "https://flatlogic.com/forum" },
      { id: 9, label: "FAQ", link: "https://flatlogic.com/forum" },
      { id: 10, type: "divider" },
      { id: 11, type: "title", label: "PROJECTS" },
      {
        id: 12,
        label: "My recent",
        link: "",
      },
      {
        id: 13,
        label: "Starred",
        link: "",
      },
      {
        id: 14,
        label: "Background",
        link: "",
      },
    ];
  }
};
