import Cookies from 'universal-cookie';

const CookieService = (function () {
    var _service;
    const cookies = new Cookies();

    function _getService() {
        if (!_service) {
            _service = this;
            return _service
        }
        return _service
    }

    function _setToken(tokenObj) {
        let expiryDate = new Date(Date.now())        
      expiryDate=expiryDate.getTime() +86400000        
     //  expiryDate=expiryDate.getTime() +60000      
        cookies.set('user_access_token', tokenObj, {
            path: '/',
            //expires: new Date(Date.now()),
            maxAge: expiryDate
        })

        cookies.set('h_stamp', expiryDate, {
            path: '/',
            //expires: new Date(Date.now()),
            maxAge: expiryDate
        })
        //cookies.set('h_stamp', expiryDate.getTime()+ 60 * 60 * 24 * 1000 , {       
        //  console.log(cookies.get('user_access_token'), new Date(Date.now()+2592000)); // Pacman
    }

    function _getAccessToken() {
        return cookies.get('user_access_token');

    }
    function _getExpiryDate() {
        return cookies.get('h_stamp');
    }
    function _clearToken() {
        cookies.remove('user_access_token', { path: '/' });
        cookies.remove('h_stamp', { path: '/' });
    }

    return {
        getService: _getService,
        setToken: _setToken,
        getAccessToken: _getAccessToken,
        getExpiryDate: _getExpiryDate,
        clearToken: _clearToken
    }
})();
export default CookieService;