import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom'
//routes
import { webRoutes } from '../../Components/Web/Routes'
import { adminRoutes } from '../../Components/Admin/Routes'
import { userRoutes } from '../../Components/Users/Routes'
import 'bootstrap/dist/css/bootstrap.min.css'
//import Private Route
import { PrivateRoute } from '../../Components/Admin/PrivateRoute'
import {U_PrivateRoute} from '../../Components/Users/U_PrivateRoute'

function App() {    
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/admin">
                        {adminRoutes.map((item, key) => {
                            if (typeof item.secureRoute !== 'undefined' && item.secureRoute == true)
                                return <Route
                                    exact={item.exact} path={item.path}
                                    element={
                                        <PrivateRoute component={<item.element />} path={item.path} />
                                    }
                                    key={key}
                                />
                            else
                                return <Route exact={item.exact} path={item.path} element={<item.element />} key={key} />
                        })}
                    </Route>
                    {/* <Route path="/user">
                        {userRoutes.map((item, key) => {
                             if (typeof item.secureRoute !== 'undefined' && item.secureRoute == true)
                             return <Route
                                 exact={item.exact} path={item.path}
                                 element={
                                     <U_PrivateRoute component={<item.element />} path={item.path} />
                                 }
                                 key={key}
                             />
                         else
                            return <Route exact={item.exact} path={item.path} element={<item.element />} key={key} />
                        })}
                    </Route> */}
                    <Route path="/">
                        {webRoutes.map((item, key) => {
                            return <Route exact={item.exact} path={item.path} element={<item.element />} key={key} />
                        })}
                    </Route>
                </Routes>
            </Router>
        </>
    );
}

export default App;
