import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
const cdnURL = process.env.REACT_APP__CDN_URL

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        overflow:'hidden',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

export default function CircularLoading() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
           {/* <img src={`${cdnURL}/Common/Page_Loader.mp4`} alt={<CircularProgress />} /> */}
           {/* <img src={loader_6} alt={<CircularProgress />} /> */}
           <video autoPlay muted loop className="d-none d-lg-block">
            <source src={`${cdnURL}/Common/Page_Loader.mp4`} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </div>
    );
}