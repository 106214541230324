export const StickerType = [
  {
    value: "2d",
    name: "2d",
  },
  {
    value: "3d",
    name: "3d",
  },
];
