import React, { Suspense, useEffect, useState,useRef } from 'react'
import useStyles from '../../../Assets/Styles/Admin/styles.js'
import Header from '../Header'
import Footer from '../Footer'
import SideBar from '../SideBar'
import CircularLoading from '../../Common/CircularLoading'
import MainContentClick from '../../Admin/MainContentClick'
import classNames from 'classnames'
import {
    AuseLayoutState
  } from "../../../Contexts/Admin/AdminLayoutContext"

const AdminLayoutvar = (props) => {
    var classes = useStyles()
    const [showLoader, setShowLoader] = useState(true)
    const mainContentRef=useRef(null)
    const mainContentClick = MainContentClick(mainContentRef)
    var { isSidebarOpened } = AuseLayoutState()
    const css_Canvas=document.body.clientWidth<991?(isSidebarOpened?"mob_overlay active":"mob_overlay"):"mob_overlay"
    useEffect(() => {
        setTimeout(() => setShowLoader(false), 2000)
    }, [])
    return (
        <div className={classNames(classes.root)}>
                <div className={classNames(classes.offcanvasOverlay,css_Canvas)}></div>
                <div className="wrapper" ref={mainContentRef}>
                    <Header />
                    <div className={classes.mainWrapper}>
                        <SideBar />
                        {mainContentClick}
                        {showLoader ? <CircularLoading /> :
                             props.children}
                    </div>

                    {/* <Footer /> */}
                </div>

        </div>
    )
}

//HOC
const AdminLayout = Component => ({ ...props }) => (
    <Suspense fallback={<CircularLoading />}>
        <AdminLayoutvar>
            <Suspense fallback={<CircularLoading />}>
                <Component {...props} />
            </Suspense>
        </AdminLayoutvar>
    </Suspense>
)
export default AdminLayout
