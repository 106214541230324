export const AllGoliveStatus = [
  {
    name: "Started",
    value: 1,
  },
  {
    name: "Ended",
    value: 2,
  },
  {
    name: "Cancelled",
    value: 3,
  },
  {
    name: "expired",
    value: 4,
  },
  {
    name: "Admin Blocked",
    value: 8,
  },
];
