import React, { Suspense, useState, useEffect } from 'react'
import * as Icons from '@material-ui/icons'
import usestyle from '../../../Assets/Styles/User/login.js'
import CircularLoading from '../../Common/CircularLoading'
import LoginFooter from '../LoginFooter'

const LoginLayoutvar = (props) => {
    var classes = usestyle();
    const [isCollapsed, setCollapsed] = useState(true);
    const [showIcon, setShowIcon] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }
    useEffect(() => {
        setTimeout(() => setShowLoader(false), 2000)
        scrollTop()
        window.addEventListener("scroll", showUp);
        return () => {
            window.removeEventListener("scroll", showUp);
        };
    }, []);
    const showUp = () => {
        const scrollTopheight = window.scrollY;
        if (scrollTopheight >= 250) {
            setShowIcon(true)
        }
        else {
            setShowIcon(false)
        }
    }
    const toggleNavbar = () => {
        setCollapsed(!isCollapsed)
        if (isCollapsed === true) {
            document.body.classList.add('canvas-open');
        }
        else {
            document.body.classList.remove('canvas-open');
        }
    }
    return (
        <div>
            {showLoader ? <CircularLoading /> :
                <div className={classes.loginRegister }>
                    <div id="scrollUp" className={showIcon ? "scrollUp" : "d-none"} title="Scroll To Top" onClick={() => scrollTop()}>
                        <Icons.ArrowUpward
                            size={1.5}
                        />
                    </div>
                   
                    {props.children}
                    <LoginFooter />
                </div>}
        </div>
    )
}

//HOC
const LoginLayout = Component => ({ ...props }) => (
    <Suspense fallback={<CircularLoading />}>
        <LoginLayoutvar>
            <Suspense fallback={<CircularLoading />}>
                <Component {...props} />
            </Suspense>
        </LoginLayoutvar>
    </Suspense>
)
export default LoginLayout