export const AllCategories = [
  {
    value: "fresher",
    name: "Fresher",
  },
  {
    value: "hallofframe",
    name: "Hall Of Frame",
  },
  {
    value: "risingstar",
    name: "Rising Star",
  },
  {
    value: "superstar",
    name: "Superstar",
  },
  {
    value: "trending",
    name: "Trending",
  },
];
