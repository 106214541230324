import React from 'react'
import { Container,Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function LoginFooter() {
    return (
        <Container fluid>
            <Row>
                <div className="login_footer">
                    <ul>
                        <li>
                            <Link to="#">Terms of Use</Link>
                        </li>
                        <li style={{'padding':'0 10px'}}>|</li>
                        <li>
                            <Link to="#">Privacy Policy</Link>
                        </li>
                    </ul>
                    <span>© 2023 AdCounty Media</span>
                </div>
            </Row>
        </Container>
    )
}
