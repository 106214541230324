import React from "react";
import { Route } from "react-router-dom"
import WebLayout from "../WebLayout";
import OtherWebLayout from "../OtherWebLayout"

const Home = WebLayout(React.lazy(() => import('../../../Pages/Web/Home')))
const Gdpr = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/Gdpr')))
const Eula = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/Eula')))
const TermsOfService = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/TermsOfService')))
const CopyrightPolicy = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/CopyrightPolicy')))
const AdPolicy = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/AdPolicy')))
const CookiePolicy = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/CookiePolicy')))
const PrivacyPolicy = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/PrivacyPolicy')))
const WalletItemPolicy = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/WalletItemPolicy')))
const RaiseConcern = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/RaiseConcern')))
const AntiPhishing = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/AntiPhishing')))
const TakeDownNotice = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/TakeDownNotice')))
const AboutUs = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/AboutUs')))
const ContactUs = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/ContactUs')))
const Partners = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/Partners')))
const JagteRaho = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/JagteRaho')))
const KhelKhelMe = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/KhelKhelMe')))
const Community = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/Community')))
const DumLagake = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/DumLagake')))
const JingleBell = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/JingleBell')))
const Hello_2023 = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/Hello_2023')))
const KaiPoChe = OtherWebLayout(React.lazy(() => import('../../../Pages/Web/KaiPoChe')))
const HappyJanuary =WebLayout(React.lazy(()=>import('../../../Pages/Web/HappyJanuary')))
const JanaGanaMana =WebLayout(React.lazy(()=>import('../../../Pages/Web/JanaGanaMana')))
const JoJetetaWahiSikandar=WebLayout(React.lazy(()=>import('../../../Pages/Web/JoJetetaWahiSikandar')))
const PyarKiKashtiMein=WebLayout(React.lazy(()=>import('../../../Pages/Web/PyarKiKashtiMein')))
const AgencyRoyale=WebLayout(React.lazy(()=>import('../../../Pages/Web/AgencyRoyale')))
const GifterNo_1=WebLayout(React.lazy(()=>import('../../../Pages/Web/GifterNo_1')))
const ResellerAgencies=OtherWebLayout(React.lazy(()=>import('../../../Pages/Web/ResellerAgencies')))
const GiftsNGlory=WebLayout(React.lazy(()=>import('../../../Pages/Web/GiftsNGlory')))
const GiftOCalypse=WebLayout(React.lazy(()=>import('../../../Pages/Web/GiftOCalypse')))
const VoohooPalooza=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooPalooza')))
const SpringMania=WebLayout(React.lazy(()=>import('../../../Pages/Web/SpringMania')))
const GiftingFever=WebLayout(React.lazy(()=>import('../../../Pages/Web/GiftingFever')))
const VoohooCarnival=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooCarnival')))
const KaroTopUP=WebLayout(React.lazy(()=>import('../../../Pages/Web/KaroTopUP')))
const StarOfTheMonth=WebLayout(React.lazy(()=>import('../../../Pages/Web/StarOfTheMonth')))
const StarAgency=WebLayout(React.lazy(()=>import('../../../Pages/Web/StarAgency')))
const StarAgencyJuly=WebLayout(React.lazy(()=>import('../../../Pages/Web/StarAgencyJuly')))
const SuperHosts=WebLayout(React.lazy(()=>import('../../../Pages/Web/SuperHosts')))
const AceGifter=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooAceGifters')))
const VoohooMonsoonMania=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooMonsoonMania')))
const VoohooDareDifferent=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooDareDifferent')))
const VoohooGatheringGreats=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooGatheringGreats')))
const VoohooKeSikander=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooKeSikander')))
const VoohooMalaamalMonsoon=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooMalaamalMonsoon')))
const IndianPride=WebLayout(React.lazy(()=>import('../../../Pages/Web/IndianPride')))
const VoohooKeSuperhosts=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooKeSuperhosts')))
const VoohooTreasureBox=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooTreasureBox')))
const HumseBadhkarKaun=WebLayout(React.lazy(()=>import('../../../Pages/Web/HumseBadhkarKaun')))
const SeptemberGiftingFever=WebLayout(React.lazy(()=>import('../../../Pages/Web/SeptemberGiftingFever')))
const VoohooKeSikanderSept=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooKeSikanderSept')))
const VoohooGotTalent=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooGotTalent')))
const VoohooKismatKonnection=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooKismatKonnection')))
const VoohooCelebrity=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooCelebrity')))
const GiftingParty=WebLayout(React.lazy(()=>import('../../../Pages/Web/GiftingParty')))
const VoohooKeSikanderOct=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooKeSikanderOct')))
const VoohooMasti=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooMasti')))
const VoohooGlory=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooGlory')))
const VoohooDiwaliDhamaka=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooDiwaliDhamaka')))
const VoohooKeSikanderNov=WebLayout(React.lazy(()=>import('../../../Pages/Web/VoohooKeSikanderNov')))

const webRoutes = [
    { path: "/", element: Home, exact: true },
    { path: "/gdpr", element: Gdpr, exact: true },
    { path: "/eula", element: Eula, exact: true },
    { path: "/terms-of-use", element: TermsOfService, exact: true },
    { path: "/copyright-policy", element: CopyrightPolicy, exact: true },
    { path: "/about-us", element: AboutUs, exact: true },
    { path: "/contact-us", element: ContactUs, exact: true },
    { path: "/ad-policy", element: AdPolicy, exact: true },
    { path: "/cookie-policy", element: CookiePolicy, exact: true },
    { path: "/privacy-policy", element: PrivacyPolicy, exact: true },
    { path: "/wallet-items-policy", element: WalletItemPolicy, exact: true },
    { path: "/raise-your-concern", element: RaiseConcern, exact: true },
    { path: "/anti-phishing", element: AntiPhishing, exact: true },
    { path: "/takedownnotice", element: TakeDownNotice, exact: true },
    { path: "/partner", element: Partners, exact: true },
    { path: "/jagte-raho", element: JagteRaho, exact: true },
    { path: "/khel-khel-me", element: KhelKhelMe, exact: true },
    { path: "/dum-laga-ke", element: DumLagake, exact: true },
    { path: "/community", element: Community, exact: true },
    { path: "/jingle-bell", element: JingleBell, exact: true },
    { path: "/hello-2023", element: Hello_2023, exact: true },
    { path: "/kai-po-che", element: KaiPoChe, exact: true },
    { path: "/happy-january", element: HappyJanuary, exact: true },
    { path: "/jana-gana-mana", element: JanaGanaMana, exact: true },
    { path: "/jo-jeeta-wahi-sikanadar", element: JoJetetaWahiSikandar, exact: true },
    { path: "/pyar-ki-kashti-mein", element: PyarKiKashtiMein, exact: true },
    { path: "/agency-royale", element: AgencyRoyale, exact: true },
    { path: "/gifter-no1", element: GifterNo_1, exact: true },
    { path: "/offline-recharge", element: ResellerAgencies, exact: true },
    { path: "/gifts-n-glory", element: GiftsNGlory, exact: true },
    { path: "/gift-o-calypse", element: GiftOCalypse, exact: true },
    { path: "/voohoo-palooza", element: VoohooPalooza, exact: true },
    { path: "/spring-mania", element: SpringMania, exact: true },
    { path: "/gifting-fever", element: GiftingFever, exact: true },
    { path: "/voohoo-carnival", element: VoohooCarnival, exact: true },
    { path: "/karo-top-up", element: KaroTopUP, exact: true },
    { path: "/star-of-the-month", element: StarOfTheMonth, exact: true },
    { path: "/star-agency", element: StarAgency, exact: true },
    { path: "/star-agency-july", element: StarAgencyJuly, exact: true },
    { path: "/super-hosts", element: SuperHosts, exact: true },
    { path: "/ace-gifter", element: AceGifter, exact: true },
    { path: "/monsoon-mania", element: VoohooMonsoonMania, exact: true },
    { path: "/dare-to-be-different", element: VoohooDareDifferent, exact: true },
    { path: "/gathering-of-greats", element: VoohooGatheringGreats, exact: true },
    { path: "/voohoo-ke-sikandar", element: VoohooKeSikander, exact: true },
    { path: "/voohoo-malamaal-monsoon", element: VoohooMalaamalMonsoon, exact: true },
    { path: "/indian-pride", element: IndianPride, exact: true },
    { path: "/voohoo-ke-superhosts", element: VoohooKeSuperhosts, exact: true },
    { path: "/treasure-box", element: VoohooTreasureBox, exact: true },
    { path: "/humse-badhkar-kaun", element: HumseBadhkarKaun, exact: true },
    { path: "/giftingfever", element: SeptemberGiftingFever, exact: true },
    { path: "/voohoo-Ke-sikander", element: VoohooKeSikanderSept, exact: true },
    { path: "/voohoo-got-talent", element: VoohooGotTalent, exact: true },
    { path: "/voohoo-kismat-konnection", element: VoohooKismatKonnection, exact: true },
    { path: "/voohoo-celebrity", element: VoohooCelebrity, exact: true },
    { path: "/gifting-party", element: GiftingParty, exact: true },
    { path: "/voohoo-Ke-sikander-october", element: VoohooKeSikanderOct, exact: true },
    { path: "/voohoo-masti", element: VoohooMasti, exact: true },
    { path: "/voohoo-glory", element: VoohooGlory, exact: true },
    { path: "/voohoo-diwali-dhamaka", element: VoohooDiwaliDhamaka, exact: true },
    { path: "/voohoo-Ke-sikander-november", element: VoohooKeSikanderNov, exact: true },
    // { path: "/recharge-coins", element: RechargeCoins, exact: true },
    // { path: "/gifting-mania", element: GiftingMania, exact: true },
    // { path: "/sunday-spotlight", element: SundaySpotlight, exact: true },
    // { path: "/download-app", element: DownloadApk, exact: true },
    // { path: "/monsoon-mania", element: MonsoonMania, exact: true },
    // { path: "/crime-master", element: CrimeMaster, exact: true },
];
export { webRoutes }

// export default function WebRoutes() {
//     return (
//         <>
//             {webRoutes.map((item, key) => {
//                 return <Route exact={item.exact} path={item.path} element={<item.element />} key={key} />
//             })}
//         </>
//     )
// }