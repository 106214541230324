import React from 'react';
import { 
    Navigate
} from 'react-router-dom';

// context
import { AuseAuthState } from '../../../Contexts/Admin/AdminAuthContext'
import CookieService from '../../../Adapters/Admin/Services/Cookie';

function PrivateRoute({ component,path }) {
    // global    
 //   const navigate = useNavigate()
    var isCookie = CookieService.getAccessToken() ? true : false;    
    //var { isAuthenticated } = AuseAuthState();
    return isCookie?component:<Navigate to="/admin/login" state={{from:path}} />
  //  return isCookie?component:navigate("/admin/login",{state:{from: path }})
   
}

export { PrivateRoute };