const LocalStorageService = (function () {
    var _service;
    function _getService() {
      if (!_service) {
        _service = this;
        return _service
      }
      return _service
    }
    function _setAuthorization(Obj) {
      localStorage.setItem('is_u_Authenticated', Obj);
    }
    function _setRole(obj) {
      localStorage.setItem('user_role', obj);
    }
    function _setUserName(obj) {
      localStorage.setItem('user_name', obj);
    }
    function _setEmail(obj) {
      localStorage.setItem('user_email', obj);
    }
    function _setImage(obj) {
      localStorage.setItem('user_img', obj);
    }
    function _setSession(obj) {
      localStorage.setItem('_u_session', obj);
    }
    function _getAuthorization() {
      return localStorage.getItem('is_u_Authenticated');
    }
    function _getRole() {
      return localStorage.getItem('user_role');
    }
    function _getUserName() {
      return localStorage.getItem('user_name');
    }
    function _getEmail() {
      return localStorage.getItem('user_email');
    }
    function _getImage() {
      return localStorage.getItem('user_img');
    }
    function _getSession() {
      return localStorage.getItem('_u_session');
    }
    function _clearAuthorization() {
      localStorage.removeItem('is_u_Authenticated');
    }
    function _clearRole() {
      localStorage.removeItem('user_role');
    }
    function _clearUserName() {
      localStorage.removeItem('user_name');
    }
    function _clearImage() {
      localStorage.removeItem('user_img');
    }
    function _clearEmail() {
      localStorage.removeItem('user_email');
    }
    return {
      getUserService: _getService,
      setUserAuthorization: _setAuthorization,
      getUserAuthorization: _getAuthorization,
      clearUserAuthorization: _clearAuthorization,
    //   setRole: _setRole,
    //   getRole: _getRole,
    //   clearRole: _clearRole,
    //   setUserName: _setUserName,
    //   getUserName: _getUserName,
    //   clearUserName: _clearUserName,
    //   setEmail: _setEmail,
    //   getEmail: _getEmail,
    //   clearEmail: _clearEmail,
    //   setImage: _setImage,
    //   getImage: _getImage,
    //   clearImage: _clearImage,
      setSession: _setSession,
      getSession: _getSession
    }
  })();
  export default LocalStorageService;