export const AllCountryCodes = [
  {
    value: "bd",
    name: "Bangladesh",
  },
  {
    value: "in",
    name: "India",
  },
  {
    value: "ae",
    name: "United Arab Emirates",
  },
  {
    value: "vn",
    name: "Viet Nam",
  },
  {
    value: "id",
    name: "Indonesia",
  },
  {
    value: "my",
    name: "Malaysia",
  },
  {
    value: "sg",
    name: "Singapore",
  },
];
