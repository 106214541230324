import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import {AauthReducer} from "../../../Contexts/Admin/AdminAuthContext";

const persistConfig = {
    key: 'root',
    storage,
}
const persistedReducer = persistReducer(persistConfig, AauthReducer)  
export const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: [thunk]
  })
  
export const persistor = persistStore(store)
