import { makeStyles } from "@material-ui/styles"
import Login_Bg from '../../Images1/Users/Login_Bg.jpg'

export default makeStyles(theme => ({
    loginRegister: {        
        fontFamily: 'Inter, sans-serif !important',
        background: `url(${Login_Bg})`,
        '& .left_logo': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '& .logo': {
                padding: '50px',
                maxWidth: '550px',
                [theme.breakpoints.down("1025")]: {
                    maxWidth: '450px',
                },
                [theme.breakpoints.down("769")]: {
                    maxWidth: '300px',
                    padding: '30px'
                },
            },
            '& .Qr_code': {
                padding: '0 100px',
                textAlign: 'center',
                [theme.breakpoints.down("1025")]: {
                    padding: '0 50px',
                },
                [theme.breakpoints.down("769")]: {
                    display: 'none'
                },
                '& .google_play, & .ios': {
                    maxWidth: '170px',
                    [theme.breakpoints.down("1025")]: {
                        maxWidth: '130px',
                    },
                }
            }
        },
        '& .login_footer': {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '50px 50px 26px 50px',
            [theme.breakpoints.down("769")]: {
                padding: '0px 50px 26px 50px',
                flexDirection: 'column',
                textAlign: 'center',
                alignItems: 'center',
            },
            '& $ul': {
                listStyle: 'none',
                display: 'inline-flex',
                padding: '0',
                alignItems: 'center',
                '& $li': {
                    color: '#ffffff',
                    '& $a': {
                        color: '#ffffff',
                        fontSize: '20px',
                        fontWeight: '500',
                        [theme.breakpoints.down("1025")]: {
                            fontSize: '18px'
                        },
                        [theme.breakpoints.down("769")]: {
                            fontSize: '14px'
                        }
                    }
                }
            },
            '& $span': {
                color: '#ffffff',
                fontSize: '20px',
                fontWeight: '500',
                [theme.breakpoints.down("1025")]: {
                    fontSize: '18px'
                },
                [theme.breakpoints.down("769")]: {
                    fontSize: '14px'
                }
            }
        },
        '& .login': {
            display: 'flex',
            flexDirection: 'column',
            padding: '160px 0px 50px 40px',
            textAlign: 'center',
            alignItems: 'center',
            [theme.breakpoints.down("769")]: {
                padding: '20px 0px !important',
            },
            '& $h3': {
                color: '#FFF',
                fontSize: '48px',
                fontWeight: '700',
                [theme.breakpoints.down("1025")]: {
                    fontSize: '32px !important',
                },
                [theme.breakpoints.down("769")]: {
                    fontSize: '24px !important',
                },
            },
            '& $a': {
                color: '#FFF',
                fontSize: '20px',
                fontWeight: '700',
                textDecoration: 'underline',
                [theme.breakpoints.down("1025")]: {
                    fontSize: '18px',
                },
                [theme.breakpoints.down("769")]: {
                    fontSize: '14px',
                },
            },
            '& .login_options': {
                margin: '30px 40px',
                [theme.breakpoints.down("769")]: {
                    margin: '20px 0px !important',
                },
                '& .btn_login': {
                    background: '#ffffff',
                    borderRadius: '50px',
                    border: 'none',
                    outline: 'none',
                    cursor: 'pointer',
                    color: '#000',
                    fontSize: '20px',
                    fontWeight: '600',
                    padding: '15px 0',
                    margin: '10px',
                    width: '100%',
                    [theme.breakpoints.down("1025")]: {
                        fontSize: '18px',
                        padding: '12px 0'
                    },
                    [theme.breakpoints.down("769")]: {
                        fontSize: '14px',
                        padding: '10px 0',
                        margin: '10px 0',
                        width: '70%',
                    },
                    [theme.breakpoints.down("525")]: {
                        width: '80%',
                    },
                    '& .img_otp': {
                        paddingRight: '15px',
                        [theme.breakpoints.down("1025")]: {
                            paddingRight: '12px'
                        },
                        [theme.breakpoints.down("769")]: {
                            paddingRight: '10px'
                        }
                    }
                },
            },
            '& .lbl_agree': {
                color: '#FFF',
                fontSize: '20px',
                fontWeight: '500',
                paddingBottom: '10px',
                [theme.breakpoints.down("1025")]: {
                    fontSize: '18px',
                },
                [theme.breakpoints.down("769")]: {
                    fontSize: '14px',
                    width: '70%',
                },
                [theme.breakpoints.down("525")]: {
                    width: '80%',
                },
            }
        },
        '& .mobile_number_login,& .otp_verification': {
            background: '#ffffff',
            borderRadius: '12px',
            margin: '150px 80px',
            [theme.breakpoints.down("1025")]: {
                margin: '120px 20px',
            },
            [theme.breakpoints.down("769")]: {
                margin: '20px auto',
                width: '75%'
            },
            [theme.breakpoints.down("525")]: {
                margin: '20px 0',
                width: '100%'
            },
            '& .selectValues': {
                display: 'flex',
                flexDirection: 'column',
                padding: '30px',
                [theme.breakpoints.down("769")]: {
                    padding: '20px 10px',
                },
                '& $h4': {
                    fontWeight: '700',
                    fontSize: '24px',
                    color: '#1A264E',
                    margin: '0',
                    [theme.breakpoints.down("1025")]: {
                        fontSize: '22px',
                    },
                    [theme.breakpoints.down("769")]: {
                        fontSize: '20px',
                    },
                    '& $img': {
                        paddingRight: '5px'
                    }
                },
                '& .lbl_select': {
                    color: '#768DA9',
                    fontWeight: '400',
                    fontSize: '14px',
                    paddingLeft: '40px',
                    marginBottom: '10px',
                    [theme.breakpoints.down("1025")]: {
                        fontSize: '12px',
                    },
                    [theme.breakpoints.down("769")]: {
                        fontSize: '10px',
                    },
                },
                '& $form': {
                    display: 'flex',
                    flexDirection: 'column',
                    '& .MuiFormControl-root': {
                        '&.MuiTextField-root': {
                            margin: '20px 0 10px !important',
                            [theme.breakpoints.down("769")]: {
                                margin: '10px 0 5px !important',
                            },
                        }
                    },
                    '& .lbl_smsUpdates': {
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontSize: '12px',
                        fontWeight: '400',
                        [theme.breakpoints.down("769")]: {
                            fontSize: '10px',
                        },
                    },
                    '& .btn_next': {
                        background: 'linear-gradient(#6231FF, #DC93FF)',
                        borderRadius: '122px',
                        color: '#ffffff',
                        cursor: 'pointer',
                        border: 'none',
                        outline: 'none',
                        padding: '12px',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: '500',
                        margin: '40px 15px 0',
                        [theme.breakpoints.down("1025")]: {
                            fontSize: '12px',
                        },
                        [theme.breakpoints.down("769")]: {
                            margin: '20px 10px 0px !important',
                            padding: '10px',
                            fontSize: '14px',
                        },
                        '& $img': {
                            paddingLeft: '5px'
                        }
                    }
                }
            }
        },
        '& .otp_verification': {
            '& .selectValues': {
                '& .content': {
                    padding: '0 40px',
                    '& .lbl_select': {
                        paddingLeft: '0 !important'
                    },
                    '& .mob_change': {
                        '& $label, & $a': {
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#1A264E',
                            textDecoration: 'none',
                            [theme.breakpoints.down("525")]: {
                                fontSize:'12px'
                            },
                        },
                        '& $a': {
                            fontWeight: '400 !important',
                            '& $img': {
                                padding: '0 5px'
                            }
                        }
                    },
                    '& .otpContainer': {
                        margin: '5% auto',
                        '& .otpInput': {
                            width: '2.8rem !important',
                            border: 'none',
                            borderBottom: '2px solid #DBE9F5',
                            height: '3rem',
                            margin: ' 0 5px',
                            fontSize: '2rem',
                            textAlign: 'center',
                            [theme.breakpoints.down("1025")]: {
                                width:'2.2rem !important',                              
                             },
                            [theme.breakpoints.down("769")]: {
                                width:'3.7rem !important',                              
                             },
                            [theme.breakpoints.down("525")]: {
                               width:'1.4rem !important',
                               height:'2rem !important',
                            },
                        }
                    },
                    '& .lbl_resend': {
                        color: '#8A8A8A',
                        fontSize: '12px',
                        fontWeight: '500',
                        paddingRight:'5px',
                        [theme.breakpoints.down("525")]: {
                            fontSize: '10px',
                         },
                    },
                    '& .link_resend': {
                        color: '#0085FF',
                        fontSize: '14px',
                        fontWeight: '600',
                        textDecoration:'none',
                        [theme.breakpoints.down("525")]: {
                            fontSize: '12px',
                         },
                    },
                    '& .span_timer':{
                        fontSize:'12px',
                        color:'#1A264E',
                        fontWeight:'500',
                        [theme.breakpoints.down("525")]: {
                            fontSize: '10px',
                         },
                    }
                },
                '& .btn_next': {
                    background: 'linear-gradient(#6231FF, #DC93FF)',
                    borderRadius: '122px',
                    color: '#ffffff',
                    cursor: 'pointer',
                    border: 'none',
                    outline: 'none',
                    padding: '12px',
                    fontFamily:'Inter, sans-serif !important',
                    fontSize: '16px',
                    fontWeight: '500',
                    margin: '40px 15px 0',
                    [theme.breakpoints.down("1025")]: {
                        fontSize: '12px',
                    },
                    [theme.breakpoints.down("769")]: {
                        margin: '20px 10px 0px !important',
                        padding: '10px',
                        fontSize: '14px',
                    },
                    '& $img': {
                        paddingLeft: '5px'
                    }
                }
            }
        }
    }
}))